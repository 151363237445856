import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TracesService {

  constructor(private readonly _httpClient: HttpClient) {
  }

  traceAccesService(codeService: string, codeFonds: string) {
    return this._httpClient.post<void>(`/api/v1/private/evenements/acces-service?codeService=${codeService}&codeFonds=${codeFonds}`, {});
  }

}
