declare const require: any;
const pkg = require('../../package.json');
const version = pkg.version;

export const environment = {
  production: true,
  ENV: 'production',
  E2E: false,
  CONTEXT_FILE_PATH: 'appconf/context.json',
  CONTEXT_LOCAL_FILE_PATH: 'appconf/context-local.json',
  PACKAGE_VERSION: version
};
