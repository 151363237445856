export class ContactAdministrateurModel {

  civilite: string;
  prenom: string;
  nom: string;
  service: string;
  telephoneFixe: string;
  email: string;
  dateFinAccesRestreint: Date;

  public static fromAdministrateur(rawContactAdministrateur) {
    return this.construireContactAdministrateur(rawContactAdministrateur);
  }

  public static fromCentralisateur(rawUtilisateurCentralisateur) {
    const contactAdministrateur = this.construireContactAdministrateur(rawUtilisateurCentralisateur);
    if (rawUtilisateurCentralisateur) {
      contactAdministrateur.service = rawUtilisateurCentralisateur.designationEmployeurCentralisateur;
    }
    return contactAdministrateur;
  }

  public static construireContactAdministrateur(rawContactAdministrateur) {
    const contactAdministrateur = new ContactAdministrateurModel();

    if (rawContactAdministrateur) {
      contactAdministrateur.civilite = rawContactAdministrateur.civilite;
      contactAdministrateur.prenom = rawContactAdministrateur.prenom;
      contactAdministrateur.nom = rawContactAdministrateur.nom;
      contactAdministrateur.service = rawContactAdministrateur.service;
      contactAdministrateur.telephoneFixe = rawContactAdministrateur.telephoneFixe;
      contactAdministrateur.email = rawContactAdministrateur.email;
      contactAdministrateur.dateFinAccesRestreint = rawContactAdministrateur.dateFinAccesRestreint;
    }

    return contactAdministrateur;
  }

}
