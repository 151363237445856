import {ActivatedRouteSnapshot} from '@angular/router';

export class RouteUtils {

  /**
   * Calcule la route complète depuis la racine
   *
   * @param activatedRouteSnapshot {ActivatedRouteSnapshot} snapshot de la route à traiter
   * @return route complète depuis la racine
   */
  public static getFullUrlFromSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
    if (!activatedRouteSnapshot) {
      return '/';
    }

    return '/' + activatedRouteSnapshot.pathFromRoot
      .map(r => r.url)
      .filter(f => !!f[0])
      .map(([f]) => f.path)
      .join('/');
  }
}
