import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Endpoint } from '@shared/endpoints';
import { LoginOptions, OAuthEvent, OAuthService, ValidationHandler } from 'angular-oauth2-oidc';
import { Constantes } from 'boot/constantes';
import { LocalStoreManager } from 'boot/service/local-store-manager.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { OAuthConfig } from '../config/oauthConfig';
import { UserProfileConfig } from '../config/user-profile.config';
import { SecurityUser } from '../entity/security-user.model';
import { IdpAxwayValidationHandler } from './IdpAxwayValidationHandler';
import { SecurityService } from './oauth-security.service';
import { environment } from 'src/environments/environment';

export class IcdcOauthSecurityService extends SecurityService {
  private revokeEndpoint: string;
  private clientId: string;
  private flowType: string;

  constructor(
    private readonly _oauthService: OAuthService,
    private readonly _localStoreManager: LocalStoreManager,
    private readonly _httpClient: HttpClient
  ) {
    super();
  }

  public initializeOauthConfiguration(
    oauthConfig: OAuthConfig,
    userProfileConfig: UserProfileConfig,
    tokenValidationHandler?: ValidationHandler
  ): void {
    this.userProfileConfig = userProfileConfig;
    this.revokeEndpoint = oauthConfig.revokeEndpoint;
    this.clientId = oauthConfig.clientId;
    this.flowType = oauthConfig.flowType;

    this._oauthService.configure({
      redirectUri: oauthConfig.redirectUri,
      loginUrl: oauthConfig.authorizeEndpoint,
      logoutUrl: oauthConfig.logoutEndpoint,
      userinfoEndpoint: oauthConfig.userInfoEndpoint,
      clientId: oauthConfig.clientId,
      scope: oauthConfig.scope,
      issuer: oauthConfig.issuer,
      postLogoutRedirectUri: oauthConfig.postLogoutRedirectUri,
      responseType: oauthConfig.flowType,
      tokenEndpoint: oauthConfig.tokenEndpoint,
      timeoutFactor: 1,
      showDebugInformation: (environment.production === false),
    });

    this._oauthService.setStorage(this._localStoreManager);
    this._oauthService.clearHashAfterLogin = false;

    this._oauthService.tokenValidationHandler = tokenValidationHandler ? tokenValidationHandler : new IdpAxwayValidationHandler();
  }

  public reconfigure(oauthConfig: OAuthConfig): void {
    this._oauthService.configure({
      redirectUri: oauthConfig.redirectUri,
      loginUrl: oauthConfig.authorizeEndpoint,
      logoutUrl: oauthConfig.logoutEndpoint,
      userinfoEndpoint: oauthConfig.userInfoEndpoint,
      clientId: oauthConfig.clientId,
      scope: oauthConfig.scope,
      issuer: oauthConfig.issuer,
      postLogoutRedirectUri: oauthConfig.postLogoutRedirectUri,
      responseType: oauthConfig.flowType,
      tokenEndpoint: oauthConfig.tokenEndpoint,
      timeoutFactor: 1,
      showDebugInformation: (environment.production === false),
    });
  }

  public onErrorEvents(): Observable<OAuthEvent> {
    return this._oauthService.events.pipe(
      filter(
        e =>
          e.type === 'invalid_nonce_in_state' ||
          e.type === 'user_profile_load_error' ||
          e.type === 'silent_refresh_error' ||
          e.type === 'silent_refresh_timeout' ||
          e.type === 'token_error' ||
          e.type === 'token_validation_error' ||
          e.type === 'token_refresh_error'
      )
    );
  }

  public onAccessTokenExpireEvent(): Observable<OAuthEvent> {
    return this._oauthService.events.pipe(filter(e => e.type === 'token_expires'));
  }

  public tryOauth(options?: LoginOptions): Promise<boolean> {
    if (this._localStoreManager.getItem(Constantes.STORAGE_KEY_SECURITY_USER)) {
      this._localStoreManager.removeItem(Constantes.STORAGE_KEY_SECURITY_USER);
    }
    return this._oauthService.tryLogin(options);
  }

  public initLoginFlow(): void {
    this._oauthService.initLoginFlow();
  }

  public getAccessToken(): string {
    return this._oauthService.getAccessToken();
  }

  public loadConnectedUser(): Promise<any> {
    if (this._localStoreManager.getItem(Constantes.STORAGE_KEY_SECURITY_USER)) {
      this._user = SecurityUser.fromStorage(JSON.parse(this._localStoreManager.getItem(Constantes.STORAGE_KEY_SECURITY_USER)));
      return Promise.resolve(this._user);
    } else {
      return this._oauthService
        .loadUserProfile()
        .then(profile => this._getEndUserDetailsFromBackend())
        .then(endUserDetails => Promise.resolve(this.createAndStoreUser(endUserDetails)))
        .catch(e => {
          console.error('Error when loading user profile !' + e);
          return Promise.reject(null);
        });
    }
  }

  public refreshUtilisateurConnectePourEmployeurTitulaire(): Promise<SecurityUser> {
    this._localStoreManager.removeItem(Constantes.STORAGE_KEY_BCR_EMPLOYEUR_CIBLE);
    return this._getEndUserDetailsFromBackend()
      .then(endUserDetails => Promise.resolve(this.createAndStoreUser(endUserDetails)))
      .catch(e => {
        console.error('Error when refresh connected user from backend for holder employer!' + e);
        return Promise.reject(null);
      });
  }

  public refreshUtilisateurConnectePourEmployeurEnDelegation(bcrEmployeurCible: string): Promise<SecurityUser> {
    this._localStoreManager.setItem(Constantes.STORAGE_KEY_BCR_EMPLOYEUR_CIBLE, bcrEmployeurCible);
    return this._getEndUserDetailsFromBackend()
      .then(endUserDetails => Promise.resolve(this.createAndStoreUser(endUserDetails)))
      .catch(e => {
        console.error('Error when refresh connected user from backend for employer in delegation !' + e);
        return Promise.reject(null);
      });
  }

  private _getEndUserDetailsFromBackend(): Promise<any> {
    return this._httpClient
      .get(Endpoint.END_USER_DETAILS)
      .toPromise()
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        err => {
          console.error('Error when loading endUserDetails from backend !' + err);
          return Promise.reject(null);
        }
      );
  }

  public logout(): void {
    this.revokeTokens();
    this._oauthService.logOut();
    this._localStoreManager.removeItem(Constantes.STORAGE_KEY_SECURITY_USER);
  }

  public isUserAuthenticated(): boolean {
    return this._oauthService.hasValidIdToken() && this._oauthService.hasValidAccessToken();
  }

  public estHabilite(habilitation: string): boolean {
    return this._user && this._user.estHabilite(habilitation);
  }

  public setupAutomaticSilentRefresh(): void {
    this._oauthService.setupAutomaticSilentRefresh();
  }

  public refreshToken(): void {
    if (this.flowType === 'code') {
      this._oauthService.refreshToken();
    } else {
      this._oauthService.silentRefresh();
    }
  }

  private createAndStoreUser(endUserDetails): SecurityUser {
    this._user = SecurityUser.from(endUserDetails);
    this._localStoreManager.setItem(Constantes.STORAGE_KEY_SECURITY_USER, JSON.stringify(this._user));
    return this._user;
  }

  private revokeTokens() {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const params = new HttpParams().set('client_id', this.clientId).set('token', this._oauthService.getRefreshToken());

    this._httpClient.post(this.revokeEndpoint, params, { headers }).subscribe();
  }
}
