import {Component, EventEmitter, forwardRef, Injector, OnDestroy, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {CaptchaService} from '../../services/captcha/captcha.service';
import {CaptchaResponseModel} from '../../models/captcha/captcha-response.model';
import {CaptchaModel} from '../../models/captcha/captcha.model';
import {AbstractComponent} from '../abstract.component';
import {takeUntil} from 'rxjs/operators';
import {ObjectUtils} from '@shared/utils';
import {Subject} from 'rxjs';
import {HttpStatus} from '@shared/models';

export const enum CaptchaMode {
  IMAGE, AUDIO
}

export interface CaptchaAnswer {
  question: string;
  answer: string;
}

@Component({
  selector: 'ie3-captcha-input',
  templateUrl: './captcha-input.component.html',
  styleUrls: ['./captcha-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaptchaInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CaptchaInputComponent),
      multi: true
    }
  ]
})
export class CaptchaInputComponent extends AbstractComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  mode = CaptchaMode.IMAGE;
  captcha: CaptchaModel;
  audioResponse: string;
  AUDIO = CaptchaMode.AUDIO;
  IMAGE = CaptchaMode.IMAGE;
  resetObservable = new Subject<void>();

  value: CaptchaResponseModel;
  @Output() response = new EventEmitter<CaptchaResponseModel>();

  error = false;
  control: NgControl;

  constructor(
    private readonly _captchaService: CaptchaService,
    private readonly _injector: Injector
  ) {
    super();
    this.value = null;
  }

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  ngOnInit() {
    this.control = this._injector.get(NgControl);
    this.resetObservable
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(() => {
        this.value = null;
        this.error = false;
        this.audioResponse = null;
        this.control.reset(null);
      });
    this.loadCaptcha();
  }

  ngOnDestroy() {
    this.resetObservable.complete();
    super.ngOnDestroy();
  }

  loadCaptcha(): void {
    this.resetObservable.next();
    this._captchaService.find()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        captcha => this.captcha = captcha
      );
  }

  emitCaptchaRequest(captchaAnswer: CaptchaAnswer) {
    const captchaRequest = new CaptchaResponseModel(this.captcha.token, captchaAnswer.answer, captchaAnswer.question);

    this.captcha.answerIsCorrect = true;
    this.writeValue(captchaRequest);
    this.onTouch();
    this.response.emit(captchaRequest);
  }

  updateCaptchaResponse(captchaAnswer: CaptchaAnswer): void {
    if (!this.captcha.hasBeenAnswered && !ObjectUtils.stringIsNullOrEmpty(captchaAnswer, 'answer')) {
      this.captcha.isExpired = false;
      this.captcha.hasBeenAnswered = true;
      this.captcha.answerGiven = captchaAnswer.answer;
      const captchaRequest = new CaptchaResponseModel(this.captcha.token, captchaAnswer.answer, captchaAnswer.question);
      this._captchaService.validate(captchaRequest)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(
          () => {
            this.captcha.answerIsCorrect = true;
            this.writeValue(captchaRequest);
            this.onTouch();
            this.response.emit(captchaRequest);
          },
          httpError => {
            if (httpError.status === HttpStatus.BAD_REQUEST
              && httpError.error
              && httpError.error.type === 'ERREUR_CAPTCHA_EXPIRE') {
              this.captcha.isExpired = true;
            }
            this.writeValue(null);
            this.captcha.answerIsCorrect = false;
            console.error(httpError);
            this.onTouch();
          }
        );
    }
  }

  switchModeTo(mode: CaptchaMode): void {
    this.mode = mode;
    this.loadCaptcha();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: any): void {
    this.value = value;
    this.onChange(this.value);
  }

  forceError() {
    this.captcha.hasBeenAnswered = true;
    this.captcha.answerIsCorrect = false;
    this.captcha.isExpired = false;
    this.value = null;
    this.onChange(this.value);
  }

  forceErrorExpired() {
    this.captcha.isExpired = true;
    this.value = null;
    this.onChange(this.value);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.captcha && this.captcha.isExpired) {
      return {expiredCaptcha: {erreur: 'captcha expire'}};
    } else {
      return this.value == null ? {invalidCaptcha: {erreur: 'captcha invalide'}} : null;
    }
  }
}
