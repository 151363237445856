import { Injectable } from '@angular/core';
import { RedirectionService } from '@shared/services/redirection.service';
import { TracesService } from '@private/modules/shared/services/traces.service';

@Injectable({
  providedIn: 'root'
})
export class Ie3CarteThematiqueService {
  constructor(private readonly _redirectionService: RedirectionService, private readonly _tracesService: TracesService) {}
  ouvrirService() {
    this._redirectionService.saveCurrentUrl();
  }

  evenementTraceAccesService(service: string, fonds: string) {
    this._tracesService.traceAccesService(service, fonds).subscribe(e => console.log(e));
  }
}
