import {ObjectUtils} from '@shared/utils/object.utils';

export class MatomoConfig {
  active: boolean;
  url: string;
  // Représente un site sur le serveur Matomo (Piwik peut mesurer plusieurs site)
  siteId: string;

  constructor(config) {
    this.active = config.active;
    this.url = config.url;
    this.siteId = config.siteId;
  }

  public peutEtreActive(): boolean {
    return this.active === true &&
      !ObjectUtils.stringIsNullOrEmpty(this.url) &&
      !ObjectUtils.stringIsNullOrEmpty(this.siteId);
  }
}
