import {Injectable} from '@angular/core';
import {OAuthStorage} from 'angular-oauth2-oidc';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';

/**
 * Provides a wrapper for accessing the web storage API and synchronizing session storage across tabs/windows.
 * more info : https://www.ebenmonney.com/sharing-sessionstorage-data-across-browser-tabs/
 */
@Injectable()
export class LocalStoreManager extends OAuthStorage {
  protected static readonly DBKEY_USER_DATA = 'user_data';
  private static readonly DBKEY_SYNC_KEYS = 'sync_keys';
  private static syncListenerInitialised = false;
  private syncKeys: string[] = [];
  private readonly initEvent = new Subject();
  private initializeStorageSyncListenerTimeout: any;

  private readonly reservedKeys: string[] = [
    'sync_keys',
    'addToSyncKeys',
    'removeFromSyncKeys',
    'getSessionStorage',
    'setSessionStorage',
    'addToSessionStorage',
    'removeFromSessionStorage',
    'clearAllSessionsStorage'
  ];

  getItem(key: string): string | null {
    return this.getData(key);
  }

  setItem(key: string, data: string): void {
    this.saveSyncedSessionData(data, key);
  }

  removeItem(key: string): void {
    this.deleteData(key);
  }

  removeItems( ...keys: string[] ): void {
    keys.forEach(key => this.removeItem(key));
  }

  /**
   * IMPORTANT!
   * This is the first thing you have to call before you use any functionality in this library.
   * You can call this on Page Load. This hooks tab synchronization up
   */
  public initialiseStorageSyncListener() {
    if (LocalStoreManager.syncListenerInitialised === true) {
      return;
    }

    LocalStoreManager.syncListenerInitialised = true;
    window.addEventListener('storage', this.sessionStorageTransferHandler, false);
    this.syncSessionStorage();
    /**
     * The onInit method is called only if another tab is opened, so the timeout is used
     * to call the onInit method and unblock any pending code, 3000ms is enough to let tabs communicate.
     * It's not flawless, but it works :)
     */
    this.getInitEvent()
      .subscribe(() => {
        if (this.initializeStorageSyncListenerTimeout) {
          clearTimeout(this.initializeStorageSyncListenerTimeout);
        }
      });
    this.initializeStorageSyncListenerTimeout = setTimeout(() => this.onInit(), 3000);
  }

  /**
   * You don’t really have to call this method, but call this when you want to unhook tab synchronization for some reason.
   */
  public deinitialiseStorageSyncListener() {
    window.removeEventListener('storage', this.sessionStorageTransferHandler, false);
    LocalStoreManager.syncListenerInitialised = false;
  }

  /**
   * Clears everything in all data stores
   */
  public clearAllStorage() {
    this.clearAllSessionsStorage();
    this.clearLocalStorage();
  }

  /**
   * Clears all session storage in all opened browser tabs. Permanent storage is not affected
   */
  public clearAllSessionsStorage() {
    this.clearInstanceSessionStorage();
    localStorage.removeItem(LocalStoreManager.DBKEY_SYNC_KEYS);
    localStorage.setItem('clearAllSessionsStorage', '_dummy');
    localStorage.removeItem('clearAllSessionsStorage');
  }

  /**
   * Clears all storage in the current browser tab only
   */
  public clearInstanceSessionStorage() {
    sessionStorage.clear();
    this.syncKeys = [];
  }

  /**
   * Clear permanent storage only. Session storage are not affected
   */
  public clearLocalStorage() {
    localStorage.clear();
  }

  /**
   * Save data into a single tab
   * Stuff you save with this is not available in other tabs
   */
  public saveSessionData(data: any, key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);
    this.removeFromSyncKeys(key);
    localStorage.removeItem(key);
    this.sessionStorageSetItem(key, data);
  }

  /**
   * Whatever you save with this function is available in all opened tabs.
   * This is what you’ll use to save a user’s Authentication Token when the user chooses not to “Remember Me”
   */
  public saveSyncedSessionData(data: any, key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);
    localStorage.removeItem(key);
    this.addToSessionStorage(data, key);
  }

  /**
   * Whatever you save with this is permanently saved on disk and is available in all opened tabs.
   * This is what you’ll use to save the Authentication token of a user who chooses to “Remember Me”
   */
  public savePermanentData(data: any, key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);
    this.removeFromSessionStorage(key);
    this.localStorageSetItem(key, data);
  }

  /**
   * Moves data that is in other storage locations (i.e. permanent storage, synced session storage) to session storage.
   * In session storage each tab has its data independent of other tabs
   */
  public moveDataToSessionStorage(key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);
    const data = this.getData(key);
    if (data == null) {
      return;
    }
    this.saveSessionData(data, key);
  }

  /**
   * Moves data in other storage locations (i.e. permanent storage, session storage) to SyncedSessionStorage.
   * Whatever is saved here is available in all opened tabs
   */
  public moveDataToSyncedSessionStorage(key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);
    const data = this.getData(key);

    if (data == null) {
      return;
    }
    this.saveSyncedSessionData(data, key);
  }

  /**
   * Moves data in other storage locations (i.e. session storage, synced session storage) to permanent storage.
   */
  public moveDataToPermanentStorage(key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);
    const data = this.getData(key);

    if (data == null) {
      return;
    }
    this.savePermanentData(data, key);
  }

  /**
   * Used to retrieve data from the data store.
   */
  public getData(key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);
    let data = this.sessionStorageGetItem(key);

    if (data == null) {
      data = this.localStorageGetItem(key);
    }

    return data;
  }

  /**
   * Used to retrieve data from the data store. This method returns an object of type T.
   * Use this when you saved an object to the data store, to have it return the object back to you.
   */
  public getDataObject<T>(key = LocalStoreManager.DBKEY_USER_DATA, isDateType = false): T {
    let data = this.getData(key);
    if (data != null) {
      if (isDateType) {
        data = new Date(data);
      }
      return data as T;
    } else {
      return null;
    }
  }

  /**
   * Deletes data from the data store.
   */
  public deleteData(key = LocalStoreManager.DBKEY_USER_DATA) {
    this.testForInvalidKeys(key);
    this.removeFromSessionStorage(key);
    localStorage.removeItem(key);
  }

  public getInitEvent(): Observable<{}> {
    return this.initEvent.asObservable();
  }

  public exists(key = LocalStoreManager.DBKEY_USER_DATA) {
    let data = sessionStorage.getItem(key);
    if (data == null) {
      data = localStorage.getItem(key);
    }

    return data != null;
  }

  public isLocalStoreManagerInitialized(): boolean {
    return LocalStoreManager.syncListenerInitialised;
  }

  private jsonTryParse(value: string) {
    try {
      return JSON.parse(value);
    } catch (e) {
      if (value === 'undefined') {
        return void 0;
      }

      return value;
    }
  }

  private readonly sessionStorageTransferHandler = (event: StorageEvent) => {
    if (!event.newValue) {
      return;
    }

    if (event.key === 'getSessionStorage') {
      if (sessionStorage.length) {
        this.localStorageSetItem('setSessionStorage', sessionStorage);
        localStorage.removeItem('setSessionStorage');
      }
    } else if (event.key === 'setSessionStorage') {
      if (!this.syncKeys.length) {
        this.loadSyncKeys();
      }
      const data = JSON.parse(event.newValue);

      for (const key in data) {
        if (this.syncKeysContains(key) && data[key] && data[key] !== 'undefined') {
          this.sessionStorageSetItem(key, JSON.parse(data[key]));
        }
      }
      this.onInit();
    } else if (event.key === 'addToSessionStorage') {
      const data = JSON.parse(event.newValue);
      this.addToSessionStorageHelper(data['data'], data['key']);
    } else if (event.key === 'removeFromSessionStorage') {
      this.removeFromSessionStorageHelper(event.newValue);
    } else if (event.key === 'clearAllSessionsStorage' && sessionStorage.length) {
      this.clearInstanceSessionStorage();
    } else if (event.key === 'addToSyncKeys') {
      this.addToSyncKeysHelper(event.newValue);
    } else if (event.key === 'removeFromSyncKeys') {
      this.removeFromSyncKeysHelper(event.newValue);
    }
  };

  private syncSessionStorage() {
    localStorage.setItem('getSessionStorage', '_dummy');
    localStorage.removeItem('getSessionStorage');
  }

  private addToSessionStorage(data: any, key: string) {
    this.addToSessionStorageHelper(data, key);
    this.addToSyncKeysBackup(key);

    this.localStorageSetItem('addToSessionStorage', {key, data});
    localStorage.removeItem('addToSessionStorage');
  }

  private addToSessionStorageHelper(data: any, key: string) {
    this.addToSyncKeysHelper(key);
    this.sessionStorageSetItem(key, data);
  }

  private removeFromSessionStorage(keyToRemove: string) {
    this.removeFromSessionStorageHelper(keyToRemove);
    this.removeFromSyncKeysBackup(keyToRemove);

    localStorage.setItem('removeFromSessionStorage', keyToRemove);
    localStorage.removeItem('removeFromSessionStorage');
  }

  private removeFromSessionStorageHelper(keyToRemove: string) {
    sessionStorage.removeItem(keyToRemove);
    this.removeFromSyncKeysHelper(keyToRemove);
  }

  private testForInvalidKeys(key: string) {
    if (!key) {
      throw new Error('key cannot be empty');
    }

    if (this.reservedKeys.some(x => x === key)) {
      throw new Error(`The storage key "${key}" is reserved and cannot be used. Please use a different key`);
    }
  }

  private syncKeysContains(key: string) {
    return this.syncKeys.some(x => x === key);
  }

  private loadSyncKeys() {
    if (this.syncKeys.length) {
      return;
    }
    this.syncKeys = this.getSyncKeysFromStorage();
  }

  private getSyncKeysFromStorage(defaultValue: string[] = []): string[] {
    const data = this.localStorageGetItem(LocalStoreManager.DBKEY_SYNC_KEYS);

    if (data == null) {
      return defaultValue;
    } else {
      return data as string[];
    }
  }

  private addToSyncKeys(key: string) {
    this.addToSyncKeysHelper(key);
    this.addToSyncKeysBackup(key);

    localStorage.setItem('addToSyncKeys', key);
    localStorage.removeItem('addToSyncKeys');
  }

  private addToSyncKeysBackup(key: string) {
    const storedSyncKeys = this.getSyncKeysFromStorage();

    if (!storedSyncKeys.some(x => x === key)) {
      storedSyncKeys.push(key);
      this.localStorageSetItem(LocalStoreManager.DBKEY_SYNC_KEYS, storedSyncKeys);
    }
  }

  private removeFromSyncKeysBackup(key: string) {
    const storedSyncKeys = this.getSyncKeysFromStorage();
    const index = storedSyncKeys.indexOf(key);

    if (index > -1) {
      storedSyncKeys.splice(index, 1);
      this.localStorageSetItem(LocalStoreManager.DBKEY_SYNC_KEYS, storedSyncKeys);
    }
  }

  private addToSyncKeysHelper(key: string) {
    if (!this.syncKeysContains(key)) {
      this.syncKeys.push(key);
    }
  }

  private removeFromSyncKeys(key: string) {
    this.removeFromSyncKeysHelper(key);
    this.removeFromSyncKeysBackup(key);

    localStorage.setItem('removeFromSyncKeys', key);
    localStorage.removeItem('removeFromSyncKeys');
  }

  private removeFromSyncKeysHelper(key: string) {
    const index = this.syncKeys.indexOf(key);

    if (index > -1) {
      this.syncKeys.splice(index, 1);
    }
  }

  private localStorageSetItem(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  private sessionStorageSetItem(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  private localStorageGetItem(key: string) {
    return this.jsonTryParse(localStorage.getItem(key));
  }

  private sessionStorageGetItem(key: string) {
    return this.jsonTryParse(sessionStorage.getItem(key));
  }

  private onInit() {
    setTimeout(() => {
      this.initEvent.next();
      this.initEvent.complete();
    });
  }
}
