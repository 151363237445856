export interface MotDePasseErreurServeurModel {
  readonly typeErreur: string;
  readonly pageErreur: string | { [key: string]: string };
}

const CREATION_ERREUR_EXPIRE = '/public/mot-de-passe/creation-erreur/expire';
const CREATION_ERREUR_INVALIDE = '/public/mot-de-passe/creation-erreur/invalide';
const REGENERATION_ERREUR_EXPIRE = '/public/mot-de-passe/regeneration-erreur/expire';
const JETON_ERREUR = '/public/mot-de-passe/jeton-erreur';

export const JETON_NON_TROUVE: MotDePasseErreurServeurModel = {
  typeErreur: 'JETON_LIEN_ACTION_NON_TROUVE',
  pageErreur: {
    ACTIVATION_COMPTE_UTILISATEUR: JETON_ERREUR,
    REGENERATION_MDP: JETON_ERREUR
  }
};

export const JETON_EXPIRE: MotDePasseErreurServeurModel = {
  typeErreur: 'JETON_LIEN_ACTION_EXPIRATION',
  pageErreur: {
    ACTIVATION_COMPTE_UTILISATEUR: CREATION_ERREUR_EXPIRE,
    REGENERATION_MDP: REGENERATION_ERREUR_EXPIRE
  }
};

export const JETON_DEJA_UTILISE: MotDePasseErreurServeurModel = {
  typeErreur: 'JETON_LIEN_ACTION_DEJA_UTILISE',
  pageErreur: {
    ACTIVATION_COMPTE_UTILISATEUR: '/public/mot-de-passe/creation-erreur/deja-utilise',
    REGENERATION_MDP: '/public/mot-de-passe/regeneration-erreur/deja-utilise'
  }
};

export const JETON_NOUVELLE_DEMANDE_EN_COURS: MotDePasseErreurServeurModel = {
  typeErreur: 'JETON_LIEN_ACTION_NOUVELLE_DEMANDE_EN_COURS',
  pageErreur: {
    ACTIVATION_COMPTE_UTILISATEUR: CREATION_ERREUR_INVALIDE,
    REGENERATION_MDP: REGENERATION_ERREUR_EXPIRE
  }
};

export const MOT_DE_PASSE_INVALIDE: MotDePasseErreurServeurModel = {
  typeErreur: 'MOT_DE_PASSE_INVALIDE',
  pageErreur: null
};

export const CRASH: MotDePasseErreurServeurModel = {
  typeErreur: 'CRASH',
  pageErreur: '/erreurs/crash'
};
