<div class="captcha-container">
  <ie3-captcha-input-image
    aria-hidden="true"
    (updateResponse)="emitCaptchaRequest($event)"
    *ngIf="mode == IMAGE"
    [captcha]="captcha"
  ></ie3-captcha-input-image>
  <ie3-captcha-input-audio
    (updateResponse)="updateCaptchaResponse($event)"
    *ngIf="mode == AUDIO"
    [captcha]="captcha"
    [reset]="resetObservable"
  ></ie3-captcha-input-audio>
</div>

<div class="row">
  <div (click)="switchModeTo(AUDIO)" *ngIf="mode == IMAGE" class="col-4" id="captchaSwitchToAudio">
    <a href="javascript:void(0);" title="Ecouter la version sonore du contrôle de sécurité">{{ 'captcha.versionSonore' | translate }}</a>
  </div>
  <div (click)="switchModeTo(IMAGE)" *ngIf="mode == AUDIO" class="col-4" id="captchaSwitchToImage">
    <a href="javascript:void(0);">{{ 'captcha.versionImage' | translate }}</a>
  </div>
  <div (click)="loadCaptcha()" class="col-8 text-end" id="captchaLoadCaptcha" aria-hidden="true">
    <a href="javascript:void(0);">{{ 'captcha.reinitialiser' | translate }}</a>
  </div>
</div>
