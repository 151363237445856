import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'security/service/auth-guard.service';
import {CrashComponent} from '@shared/components/page/error/crash/crash.component';
import {UnavailableComponent} from '@shared/components/page/error/unavailable/unavailable.component';
import {PageNotFoundComponent} from '@shared/components/page/error/pagenotfound/pagenotfound.component';
import {UnauthorizedComponent} from '@shared/components/page/error/unauthorized/unauthorized.component';
import {PublicAuthenticatedGuard} from 'security/service/public-auth-guard.service';
import {NoAuthGuard} from 'security/service/no-auth-guard.service';
import {HabilitationsGuard} from 'security/service/habilitations-guard.service';
import {SitemapComponent} from '../app/sitemap/sitemap.component';

/**
 * L'ordre des routes a une importance pour l'enchainement des auth guards
 */
const routes: Routes = [
  {
    path: 'public',
    loadChildren: () => import('../app/public/public.module').then(m => m.PublicModule),
    canActivate: [PublicAuthenticatedGuard]
  },
  {
    path: 'erreurs/non-autorise',
    component: UnauthorizedComponent,
    canActivate: [NoAuthGuard],
    data: {
      breadcrumb: {
        label: 'pageErreur.403.titre'
      }
    }
  },
  {
    path: 'erreurs/non-trouve',
    component: PageNotFoundComponent,
    canActivate: [NoAuthGuard],
    data: {
      breadcrumb: {
        label: 'pageErreur.404.titre'
      }
    }
  },
  {
    path: 'erreurs/non-disponible',
    component: UnavailableComponent,
    canActivate: [NoAuthGuard],
    data: {
      breadcrumb: {
        label: 'pageErreur.503.titre'
      }
    }
  },
  {
    path: 'erreurs/crash',
    component: CrashComponent,
    canActivate: [NoAuthGuard],
    data: {
      breadcrumb: {
        label: 'pageErreur.500.titre'
      }
    }
  },
  {
    path: 'plan-du-site',
    component: SitemapComponent
  },
  {
    path: '',
    loadChildren: () => import('../app/private/private.module').then(m => m.PrivateModule),
    canActivate: [AuthGuard],
    canActivateChild: [HabilitationsGuard]
  },
  {
    path: '**',
    redirectTo: 'erreurs/non-trouve',
    pathMatch: 'full'
  }
];

export const routing = RouterModule.forRoot(routes, {
    useHash: true,
    initialNavigation: 'disabled',
    scrollPositionRestoration: 'top',
    enableTracing: false,
    relativeLinkResolution: 'legacy'
});
