import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { routing } from './boot.routing';
import { EnvironmentService } from './context/environment.service';
import { MultiTranslateHttpLoader } from './i18n/multi-translate-http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslationDataService } from './i18n/translation-data.service';
import { SecurityModule } from 'security/security.module';
import { LocalStoreManager } from './service/local-store-manager.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IhmCommunService } from 'boot/service/ihm-commun.service';
import { LoadingModule } from 'boot/loading/loading.module';
import { SharedModule } from '@shared/shared.module';
import { CommonHttpRequestInterceptor } from 'boot/interceptor/common-http-request.interceptor';
import { VanillaHttpService } from '@shared/services/vanilla-http.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ParametrageService } from '@shared/services/parametrage/parametrage.service';
import {
  Ie3FilArianeModule,
  Ie3PaginationConfig,
  Ie3SimpleTableConfig,
  Ie3ThematiquesWrapperModule,
  Ie3CarteThematiqueModule
} from 'ie3-ihm-lib-ngx';
import { Ie3CarteThematiqueService } from 'boot/service/ie3-carte-thematique.service';
import { Ie3FilArianeService } from 'boot/service/ie3-fil-ariane.service';
import { Ie3ThematiquesWrapperService } from 'boot/service/ie3-thematiques-wrapper.service';
import { MatomoModule } from 'boot/analytics/matomo.module';
import { SitemapComponent } from '../app/sitemap/sitemap.component';
import { BootComponent } from 'boot/boot.component';

//////////////////////////////
// Module bootstrap
//////////////////////////////
export function httpLoaderFactory(translationFilesService: TranslationDataService, vanillaHttpService: VanillaHttpService) {
  return new MultiTranslateHttpLoader(translationFilesService, vanillaHttpService);
}

export function translationConfigLoaderFactory(translationFilesService: TranslationDataService) {
  return () => {
    return translationFilesService.load();
  };
}

export function parametrageLoaderFactory(parametrageService: ParametrageService, environmentService: EnvironmentService) {
  return () => {
    return environmentService.load().then(() => {
      return parametrageService.load().toPromise();
    });
  };
}

export function tableConfigFactory(translateService: TranslateService) {
  return Ie3SimpleTableConfig.withOptions({
    messages: {
      chargementEnCours: translateService.instant('utilisateur.liste.chargementEnCours')
    }
  });
}

export function paginationConfigFactory(translateService: TranslateService) {
  return Ie3PaginationConfig.withOptions({
    messages: {
      paginationPrecedent: translateService.instant('utilisateur.liste.pagePrecedente'),
      paginationSuivant: translateService.instant('utilisateur.liste.pageSuivante'),
      paginationNombreResultatsTemplate: translateService.instant('utilisateur.liste.nombreDeResultats')
    }
  });
}

registerLocaleData(localeFr, 'fr');

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    routing,
    HttpClientModule,
    SecurityModule,
    LoadingModule,
    MatomoModule,
    Ie3CarteThematiqueModule.forRoot(Ie3CarteThematiqueService),
    Ie3FilArianeModule.forRoot(Ie3FilArianeService),
    Ie3ThematiquesWrapperModule.forRoot(Ie3ThematiquesWrapperService),
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [TranslationDataService, VanillaHttpService]
      }
    })
  ],
  declarations: [BootComponent, SitemapComponent],
  providers: [
    TranslationDataService,
    EnvironmentService,
    LocalStoreManager,
    IhmCommunService,
    {
      provide: APP_INITIALIZER,
      useFactory: parametrageLoaderFactory,
      deps: [ParametrageService, EnvironmentService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: translationConfigLoaderFactory,
      deps: [TranslationDataService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonHttpRequestInterceptor,
      multi: true
    },
    {
      provide: Ie3SimpleTableConfig,
      useFactory: tableConfigFactory,
      deps: [TranslateService]
    },
    {
      provide: Ie3PaginationConfig,
      useFactory: paginationConfigFactory,
      deps: [TranslateService]
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' }
  ],
  bootstrap: [BootComponent]
})
export class BootModule {}
