import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SecurityService } from 'security/service/oauth-security.service';
import { Observable } from 'rxjs';
import { EmployeurModel } from '@private/modules/compte/models/employeur.model';
import { map } from 'rxjs/operators';
import { ContactAdministrateurModel } from '@shared/models/contact-administrateur.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeurService {
  protected _employeur: EmployeurModel;

  constructor(private readonly _httpClient: HttpClient, private readonly _securityService: SecurityService) {}

  getAdministrateurs(): Observable<ContactAdministrateurModel[]> {
    return new Observable<ContactAdministrateurModel[]>(subscriber => {
      const connectedUser = this._securityService.getConnectedUser();
      this._httpClient
        .get<any[]>(`/api/v1/private/employeur/${connectedUser.bcrEmployeurCible}/administrateurs`)
        .pipe(
          map(response =>
            response
              .map(a => ContactAdministrateurModel.fromAdministrateur(a))
          )
        )
        .subscribe(
          response => {
            subscriber.next(response);
            subscriber.complete();
          },
          (...args) => {
            subscriber.error(args);
            subscriber.complete();
          }
        );
    });
  }
}
