export class Constantes {
  static readonly STORAGE_KEY_PROVENANCE_MAIL_DELEGATION = 'provenance-mail-delegation';
  static readonly STORAGE_KEY_COMPTE_BLOQUE = 'compte-bloque';
  static readonly STORAGE_KEY_BCR_EMPLOYEUR_CIBLE = 'bcrEmployeurCible';
  static readonly STORAGE_KEY_SECURITY_USER = 'securityUser';
  static readonly STORAGE_KEY_MODALE_DELEGATION = 'modale-delegation';

  static readonly URL_PARAM_PROVENANCE_MAIL_DELEGATION = 'mailDelegation';
  static readonly URL_PARAM_ZONE_DELEGATION = 'zoneDelegation';
  static readonly STATISTIQUE_TABLE_CONTEXT = 'STATISTIQUE_TABLE_CONTEXT';
  static readonly CONTRAT_URL_PARAMS = 'CONTRAT_URL_PARAMS';
  static readonly CONTRAT_TABLE_CONTEXT = 'CONTRAT_TABLE_CONTEXT';
  static readonly IE3_GESTION_UTILISATEUR_TITULAIRE_TABLE_CONTEXT = 'IE3_GESTION_UTILISATEUR_TITULAIRE_TABLE_CONTEXT';
  static readonly FLUX_CONTRAT_TABLE_CONTEXT = 'FLUX_CONTRAT_TABLE_CONTEXT';
  static readonly LAST_INTERACTION = 'ie3-ihm-commun-date-derniere-interaction';
  static readonly TIME_DECONNEXION = 'ie3-ihm-commun-temps-avant-deconnexion';
  static readonly ALERTE_CGU_KEY = 'CGU';
  static readonly ALERTE_DELEGATION_KEY = 'DELEGATION';
}
