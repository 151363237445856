import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgbAccordionModule,
  NgbDatepickerModule,
  NgbModalModule,
  NgbPaginationModule,
  NgbTooltipConfig,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { AsideSigninComponent } from './components/layout/aside-signin/aside-signin.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepComponent, StepContent } from './components/stepper/step/step.component';
import { CaptchaInputComponent } from './components/captcha/captcha-input.component';
import { CaptchaInputAudioComponent } from '@shared/components/captcha/captcha-input-audio.component';
import { CaptchaInputImageComponent } from '@shared/components/captcha/captcha-input-image.component';
import { CaptchaService } from './services/captcha/captcha.service';
import { SyntheseVocaleComponent } from './components/synthese-vocale/synthese-vocale.component';
import { InputCritereComponent } from '@shared/components/input-criteres/input-critere/input-critere.component';
import { InputCriteresComponent } from './components/input-criteres/input-criteres.component';
import { RouterModule } from '@angular/router';
import { AppliquerLienDirective } from '@shared/directives/appliquer-lien.directive';
import { InformationsPersonnellesComponent } from './components/informations-personnelles/informations-personnelles.component';
import { AdresseProfessionnelleComponent } from './components/adresse-professionnelle/adresse-professionnelle.component';
import { EtatUtilisateurComponent } from '@shared/components/utilisateur/etat-utilisateur.component';
import { AffichageIdentifiantComponent } from '@shared/components/affichage-identifiant/affichage-identifiant.component';
import { EtatCivilComponent } from './components/etat-civil/etat-civil.component';
import { ParametrageService } from '@shared/services/parametrage/parametrage.service';
import { MotDePasseService } from '@shared/services/mdp.service';
import { WrapperComponent } from './components/wrapper/wrapper.component';
import { EmployeurService } from '@shared/services/employeur.service';
import { CrashComponent } from '@shared/components/page/error/crash/crash.component';
import { PageNotFoundComponent } from '@shared/components/page/error/pagenotfound/pagenotfound.component';
import { UnauthorizedComponent } from '@shared/components/page/error/unauthorized/unauthorized.component';
import { UnavailableComponent } from '@shared/components/page/error/unavailable/unavailable.component';
import { ErrorGenericComponent } from '@shared/components/page/error/error.generic.component';
import { VanillaHttpService } from '@shared/services/vanilla-http.service';
import { RedirectionService } from '@shared/services/redirection.service';
import { HabilitationDirective } from '@shared/directives/habilitation.directive';
import { FicheAdministrateurComponent } from './components/fiche-administrateur/fiche-administrateur.component';
import { ValidationCguModalComponent } from './components/cgu/validation-cgu-modal.component';
import { ModalInfoComponent } from './components/modal/modal-info.component';
import { HttpResponseService } from '@shared/services/http-response.service';
import { CompteBloqueModalComponent } from '@shared/components/compte-bloque-modal/compte-bloque-modal.component';
import { ListeAdministrateursComponent } from './components/liste-administrateur/liste-administrateurs.component';
import { Ie3AlertModule, Ie3ButtonModule, Ie3FilArianeModule, Ie3InputFormModule, SafeModule } from 'ie3-ihm-lib-ngx';
import { Ie3BadgeComponent } from './components/badge/badge.component';
import { SelectFederationComponent } from './components/select-federation/select-federation.component';
import { SiretDisponibleValidatorService } from '@shared/validators/siret-disponible';
import { EtablissementService } from '@private/modules/etablissement/services/etablissement.service';
import { FinalisationComponent } from '@shared/components/finalisation/finalisation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    Ie3ButtonModule,
    Ie3AlertModule,
    Ie3InputFormModule,
    Ie3FilArianeModule,
    SafeModule
  ],
  declarations: [
    AsideSigninComponent,
    StepperComponent,
    StepComponent,
    StepContent,
    CaptchaInputComponent,
    CaptchaInputAudioComponent,
    CaptchaInputImageComponent,
    InputCritereComponent,
    InputCriteresComponent,
    SyntheseVocaleComponent,
    AppliquerLienDirective,
    HabilitationDirective,
    InformationsPersonnellesComponent,
    AdresseProfessionnelleComponent,
    EtatUtilisateurComponent,
    AffichageIdentifiantComponent,
    EtatCivilComponent,
    ErrorGenericComponent,
    WrapperComponent,
    CrashComponent,
    PageNotFoundComponent,
    UnauthorizedComponent,
    UnavailableComponent,
    FicheAdministrateurComponent,
    ListeAdministrateursComponent,
    ValidationCguModalComponent,
    CompteBloqueModalComponent,
    Ie3BadgeComponent,
    ModalInfoComponent,
    SelectFederationComponent,
    FinalisationComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbTooltipModule,
    AsideSigninComponent,
    StepperComponent,
    StepComponent,
    StepContent,
    CaptchaInputComponent,
    CaptchaInputAudioComponent,
    CaptchaInputImageComponent,
    InputCritereComponent,
    InputCriteresComponent,
    SyntheseVocaleComponent,
    AppliquerLienDirective,
    HabilitationDirective,
    InformationsPersonnellesComponent,
    AdresseProfessionnelleComponent,
    EtatUtilisateurComponent,
    AffichageIdentifiantComponent,
    EtatCivilComponent,
    ErrorGenericComponent,
    WrapperComponent,
    CrashComponent,
    PageNotFoundComponent,
    UnauthorizedComponent,
    UnavailableComponent,
    FicheAdministrateurComponent,
    ListeAdministrateursComponent,
    Ie3BadgeComponent,
    ValidationCguModalComponent,
    CompteBloqueModalComponent,
    ModalInfoComponent,
    SelectFederationComponent,
    FinalisationComponent,
    SafeModule
  ]
})
export class SharedModule {

  /**
   * Permet d'importer les providers uniquement à un seul endroit : BootModule.
   * Sans ça, les services sont dupliqués et ne sont donc plus des singletons
   */
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CaptchaService,
        ParametrageService,
        MotDePasseService,
        EmployeurService,
        VanillaHttpService,
        RedirectionService,
        HttpResponseService,
        EtablissementService,
        SiretDisponibleValidatorService
      ]
    };
  }
  constructor(ngbTooltipConfig: NgbTooltipConfig) {
    // évite plein de problèmes, surtout pour IE
    ngbTooltipConfig.container = 'body';
  }
}
