import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EtablissementConsultationModel } from '@private/modules/etablissement/models/etablissement-consultation.model';
import { EtatFonctionEnum } from '@private/modules/shared/models/etat-fonction.enum';
import { FonctionEtablissementEnum } from '@private/modules/shared/models/fonction-etablissement.enum';
import { ObjectUtils } from '@shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityService } from 'security/service/oauth-security.service';

import { EtablissementRelationModel } from '../models/etablissement-relation.model';
import { EtablissementModel } from '../models/etablissement.model';
import { SecurityUser } from 'security/entity/security-user.model';
import { Fonds } from '@shared/enums/fonds.enum';

@Injectable({
  providedIn: 'root'
})
export class EtablissementService {
  private _securityUser: SecurityUser;

  constructor(private readonly _httpClient: HttpClient, private readonly _securityService: SecurityService) {
    this._securityUser = this._securityService.getConnectedUser();
  }

  getEtablissements(bcr): Observable<EtablissementConsultationModel[]> {
    return new Observable<EtablissementConsultationModel[]>(subscriber => {
      this._httpClient
        .get<EtablissementConsultationModel[]>(`/api/v1/private/etablissements/${bcr}/relations`)
        .pipe(map(response => response.map(etablissement => this.getEtablissementConsultationModel(etablissement))))
        .subscribe(
          response => {
            subscriber.next(response);
            subscriber.complete();
          },
          (...args) => {
            subscriber.error(args);
            subscriber.complete();
          }
        );
    });
  }

  getEtablissementBySiret(siret: string): Observable<EtablissementModel> {
    return this._httpClient.get<EtablissementModel>('/api/v1/private/etablissements/find', {
      params: { siret }
    });
  }

  getEtablissementByCodeFonds(codeFonds: string): Observable<EtablissementModel[]> {
    return this._httpClient.get<EtablissementModel[]>('/api/v1/private/etablissements/find/light', {
      params: { codeFonds }
    });
  }

  createEtablissement(etablissement: EtablissementModel): Observable<EtablissementModel> {
    return this._httpClient.post<EtablissementModel>('/api/v1/private/etablissements', etablissement);
  }

  updateEtablissement(etablissement: EtablissementModel): Observable<EtablissementModel> {
    return this.createEtablissement(etablissement);
  }

  createEtablissementRattache(etablissement: EtablissementRelationModel): Observable<HttpResponse<any>> {
    const bcr = etablissement.etablissementPrincipal.bcr;
    return this._httpClient.post(`/api/v1/private/etablissements/${bcr}/relations`, etablissement, { observe: 'response' });
  }

  getEtatFonction(etatFonctionCode: string): string {
    let etatFonctionLibelle!: string;
    switch (etatFonctionCode) {
      case EtatFonctionEnum.DESACTIVE:
        etatFonctionLibelle = 'Désactivé';
        break;
      case EtatFonctionEnum.EN_COURS:
        etatFonctionLibelle = 'En cours';
        break;
      default:
        etatFonctionLibelle = 'N/A';
        break;
    }

    return etatFonctionLibelle;
  }

  private getEtablissementConsultationModel(etablissement: any): EtablissementConsultationModel {
    if (ObjectUtils.isNullOrUndefined(etablissement)) {
      return null;
    }

    const etablissementLie = etablissement.etablissementLie;
    const etablissementPrincipal = etablissement.etablissementPrincipal;

	// Using a ("DD/MM/YYYY HH:MM:SS") representation for the modified date, as it is used in the display component. No specific processing for filter management
	let nouvelleDateFormate: Date = new Date(etablissement.dateModification.toString());
    let nouvelleDateFormateString: string = nouvelleDateFormate.toLocaleString("fr-BE");

    return new EtablissementConsultationModel(
      etablissementPrincipal,
      etablissementLie,
      this.getEtablissementFonction(etablissement.fonction),
      etablissement.fonction,
      this.checkEtatFonctionOrStatutEtab(etablissement),
      etablissement.etat,
      etablissement.modificationEtatAutorisee,
      etablissement.dateModification,
      nouvelleDateFormateString
    );
  }

  private getEtablissementFonction(etablissementFonction): string {
    let fonctionLibelle!: string;
    switch (etablissementFonction) {
      case FonctionEtablissementEnum.DELEGATAIRE_GESTION:
        fonctionLibelle = 'Délégataire de gestion';
        break;
      case FonctionEtablissementEnum.EMETTEUR:
        fonctionLibelle = 'Emetteur';
        break;
      case FonctionEtablissementEnum.FEDERATION:
        fonctionLibelle = 'Fédération';
        break;
      case FonctionEtablissementEnum.OGRS:
        fonctionLibelle = 'OGRS';
        break;
      case FonctionEtablissementEnum.OPERATEUR:
        fonctionLibelle = 'Opérateur';
        break;
      case FonctionEtablissementEnum.TITULAIRE:
        fonctionLibelle = 'Titulaire';
        break;
      default:
        fonctionLibelle = 'N/A';
        break;
    }
    return fonctionLibelle;
  }

  private checkEtatFonctionOrStatutEtab(etablissement: any): string {
    return this._securityUser.fondsEmployeur === Fonds.IOP &&
      etablissement.fonction === FonctionEtablissementEnum.TITULAIRE &&
      etablissement.etablissementPrincipal.estBloque === true
      ? 'Bloqué'
      : this.getEtatFonction(etablissement.etat);
  }
}
