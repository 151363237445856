import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EtablissementService } from '@private/modules/etablissement/services/etablissement.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiretDisponibleValidatorService {
  constructor(private _etablissementService: EtablissementService) {}

  checkSiretIsPresent(siretEmployeur: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value === siretEmployeur) {
        return of(null);
      }

      return this._etablissementService.getEtablissementBySiret(control.value).pipe(
        debounceTime(1000),
        map(etab => (etab ? { siretFound: true } : null)),
        catchError(err => {
          return err.status === 404 ? of(null) : of({ siretServiceError: true });
        })
      );
    };
  }

  isSiretValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value || control.value.length < 14) {
        return null;
      }
      const isLuhnValid = this.isLuhnValid(control.value);

      if (isLuhnValid) {
        return null;
      } else {
        control.markAsTouched();
        return { siretInvalid: true };
      }
    };
  }

  private isLuhnValid(siret: string): boolean {
    return this.checkSiren(siret.substring(0, 9)) && this.checkSiret(siret);
  }

  private checkSiren(siren: string): boolean {
    return this.checkSirentOrSiret(siren);
  }

  private checkSiret(siret: string): boolean {
    return this.checkSirentOrSiret(siret);
  }

  private checkSirentOrSiret(sirenOrSiret: string): boolean {
    const isSiren = sirenOrSiret.length === 9;
    const isSiret = sirenOrSiret.length === 14;

    let somme = 0;
    for (let i = 0; i < sirenOrSiret.length; i++) {
      const chiffre = sirenOrSiret.charCodeAt(i) - 55 + 7;
      if ((isSiren && i % 2 === 0) || (isSiret && i % 2 !== 0)) {
        somme += chiffre;
      } else {
        const rank = 2 * chiffre;
        if (rank < 10) {
          somme += rank;
        } else {
          somme += 1 + rank - 10;
        }
      }
    }

    return somme % 10 === 0;
  }
}
