import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ThematiqueModel } from 'ie3-ihm-lib-ngx';
import { KeyCacheEnum, SimpleCacheService } from '../../shared/services/caches/simple-cache.service';

@Injectable({
  providedIn: 'root'
})
export class ThematiquesService {
  constructor(private readonly _httpClient: HttpClient, private readonly _cacheService: SimpleCacheService) {}

  public getThematique(identifiant: number): ThematiqueModel {
    const thematiques = this._cacheService.getCachedDataPourEmployeurCible(KeyCacheEnum.THEMATIQUES, []);
    return thematiques.find(t => t.id === identifiant);
  }

  public recupererThematiques(): ThematiqueModel[] {
    return this._cacheService.getCachedDataPourEmployeurCible(KeyCacheEnum.THEMATIQUES, []);
  }

  public chargerLesThematiques(): Observable<ThematiqueModel[]> {
    const thematiquesEmployeurCible = this._cacheService.getCachedDataPourEmployeurCible(KeyCacheEnum.THEMATIQUES);
    if (thematiquesEmployeurCible && thematiquesEmployeurCible.length) {
      return of(thematiquesEmployeurCible);
    }

    return new Observable<ThematiqueModel[]>(subscriber => {
      this._httpClient
        .get(`/api/v1/private/thematiques`)
        .pipe(
          map((response: any[]) => {
            const thematiques: ThematiqueModel[] = [];
            response.forEach(r => thematiques.push(ThematiqueModel.from(r)));
            return thematiques;
          })
        )
        .subscribe(
          response => {
            this._cacheService.setCachedDataPourEmployeurCible(KeyCacheEnum.THEMATIQUES, response);
            subscriber.next(response);
            subscriber.complete();
          },
          (...args) => {
            subscriber.error(args);
            subscriber.complete();
          }
        );
    });
  }
}
