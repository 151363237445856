/* tslint:disable */

import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {BootModule} from 'boot/boot.module';
import 'zone.js/dist/zone';
import {environment} from './environments/environment';


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
 .bootstrapModule(BootModule)
 .catch(err => console.error(err));
