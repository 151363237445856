import { IhmCommunService } from './ihm-commun.service';
import { AbstractIe3ThematiquesWrapperService, ObjectUtils } from 'ie3-ihm-lib-ngx';

import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Ie3ThematiquesWrapperService extends AbstractIe3ThematiquesWrapperService {
  constructor(private readonly _ihmCommunService: IhmCommunService) {
    super();
  }

  public intIhmCommun(menuTagId: string, menuAriaLabel: string): Observable<any> {
    const menuAriaCheck = ObjectUtils.stringIsNullOrEmpty(menuAriaLabel);
    const menuTagCheck = ObjectUtils.stringIsNullOrEmpty(menuTagId);

    if (menuTagCheck || menuAriaCheck) {
      return EMPTY;
    }

    return this._ihmCommunService.init({
      menuTagId,
      menuAriaLabel
    });
  }
  public getIe3FrontUrl(): string {
    return '#/thematiques';
  }
}
