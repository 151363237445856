import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { VanillaHttpService } from '@shared/services/vanilla-http.service';

@Injectable()
export class TranslationDataService {
  public translationFiles;

  constructor(private readonly _vanillaHttpService: VanillaHttpService) {}

  load() {
    return new Promise((resolve, reject) => {
      // On utilise pas HttpClient car il n'est pas encore initialisé et nous requêtons une url locale non sécurisée
      this._vanillaHttpService
        .httpGet(`assets/i18n/i18n-data.json`)
        .pipe(
          catchError(err => {
            console.error('Error while loading translation config file');
            reject(err);
            return EMPTY;
          })
        )
        .subscribe((data: any) => {
          this.translationFiles = data.files;
          resolve(true);
        });
    });
  }
}
