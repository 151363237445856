import { EtablissementLight } from './etablissement-light.model';

export class EtablissementConsultationModel {
  etablissementLie: EtablissementLight;
  etablissementPrincipal: EtablissementLight;
  fonction: string;
  fonctionId: string;
  etat: string;
  etatId: string;
  modificationEtatAutorisee: boolean;
  dateModification: Date;
  dateModificationFiltre: string;

  constructor(
    etablissementPrincipal: EtablissementLight,
    etablissementLie: EtablissementLight,
    fonction: string,
    fonctionId: string,
    etat: string,
    etatId: string,
    modificationEtatAutorisee: boolean,
    dateModification: Date,
    dateModificationFiltre: string
  ) {
    this.etablissementPrincipal = etablissementPrincipal;
    this.etablissementLie = etablissementLie;
    this.fonction = fonction;
    this.fonctionId = fonctionId;
    this.etat = etat;
    this.etatId = etatId;
    this.modificationEtatAutorisee = modificationEtatAutorisee;    
    this.dateModification = dateModification;    
    this.dateModificationFiltre = dateModificationFiltre;
  }
}
