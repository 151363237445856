import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VanillaHttpService {

  /**
   * Fait une requête HTTP GET
   * @param url Url à appeler
   * @returns {Promise<>}
   */
  public httpGet(url): Observable<any> {
    return new Observable<any>(subscriber => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.onload = function() {
        if (xhr.readyState === 4) {
          if (xhr.status >= 200 && xhr.status < 400) {
            subscriber.next(JSON.parse(xhr.responseText));
            subscriber.complete();
          } else {
            subscriber.error(xhr.statusText);
            subscriber.complete();
          }
        }
      };
      xhr.onerror = function() {
        subscriber.error(xhr.statusText);
        subscriber.complete();
      };
      xhr.send(null);
    });
  }
}
