import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {SecurityService} from './oauth-security.service';
import {EnvironmentService} from 'boot/context/environment.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly _securityService: SecurityService,
    private readonly _environmentService: EnvironmentService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this._environmentService.config.authEnabled) {
      return true;
    }

    if (!this._securityService.isUserAuthenticated()) {
      this._securityService.initLoginFlow();
      return false;
    }

    return this._securityService.isUserAuthenticated();
  }
}
