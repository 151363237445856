export abstract class GenericCacheService<T> {

  protected _caches: Map<string, T> = new Map<string, T>();

  constructor() {
  }

  public getCachedData(keyCache: string, dataToReturnIfNotPresent: any = null): T {
    const data = this._caches.get(keyCache);
    return data ? data : dataToReturnIfNotPresent;
  }

  public setCachedData(keyCache: string, data: T) {
    this._caches.set(keyCache, data);
  }

  public clearAllCaches() {
    this._caches.clear();
  }

  public clearCache(keyCache: string) {
    this._caches.delete(keyCache);
  }

}
