import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'ie3-compte-bloque-modal',
  templateUrl: 'compte-bloque-modal.component.html'
})
export class CompteBloqueModalComponent {

  constructor(
    private readonly _router: Router) {
  }

  deconnexion() {
    this._router.navigateByUrl('/logout');
  }
}
