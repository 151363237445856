import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from 'boot/context/environment.service';
import { ThematiqueModel } from 'ie3-ihm-lib-ngx';
import { ThematiquesService } from '@private/modules/thematiques/services/thematiques.service';

declare var Ie3IhmCommunStore;
declare var Ie3IhmCommunGestionCookiesModal;

@Component({
  selector: 'ie3-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent implements OnInit {
  urlEtablissement: string;
  urlDocumentRgpd: string;
  urlDocumentCgus: string;
  urlContact: string;
  urlDocumentML: string;

  thematiques: ThematiqueModel[];

  constructor(private readonly _environmentService: EnvironmentService, private readonly _thematiqueService: ThematiquesService) {}

  ngOnInit() {
    this.urlEtablissement = this._environmentService.config.ihmEtablissementUrl;
    this.urlDocumentML = Ie3IhmCommunStore.getConfiguration().urls.mentionsLegales;
    this.urlDocumentCgus = Ie3IhmCommunStore.getConfiguration().urls.cgus;
    this.urlDocumentRgpd = Ie3IhmCommunStore.getConfiguration().consent.url;

    this._thematiqueService.chargerLesThematiques().subscribe(res => {
      this.thematiques = res;
    });
  }

  openModalCookies() {
    Ie3IhmCommunGestionCookiesModal.openCookiesManagementModal();
  }
}
