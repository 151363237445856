import { LoginOptions, OAuthEvent, ValidationHandler } from 'angular-oauth2-oidc';
import { Constantes } from 'boot/constantes';
import { LocalStoreManager } from 'boot/service/local-store-manager.service';
import { EMPTY, Observable } from 'rxjs';

import { OAuthConfig } from '../config/oauthConfig';
import { UserProfileConfig } from '../config/user-profile.config';
import { EtatDelegationEnum, ProfilEmployeurEnum, SecurityUser } from '../entity/security-user.model';
import { SecurityService } from './oauth-security.service';

export class OauthSecurityServiceMock extends SecurityService {
  endUserDetailsEmployeurTitulaire = {
    identifiant: 'LPI000585',
    nom: 'Guerin',
    prenom: 'Yoann',
    civilite: 'MONSIEUR',
    email: 'guerin.yoann@cdc.fr',
    telephoneFixe: '0512345678',
    siretEmployeur: '38043673300014',
    bcrEmployeurTitulaire: '01AAA002',
    bcrEmployeurEnDelegation: null,
    designationEmployeurTitulaire: 'CCAS DE LA COUARDE SUR MER',
    designationEmployeurEnDelegation: null,
    profilEmployeurTitulaire: ProfilEmployeurEnum.INTERMEDIAIRE,
    service: 'DEI',
    etat: 'ACTIF',
    profil: 'ADMINISTRATEUR',
    etatDelegation: EtatDelegationEnum.NON_AUTORISEE,
    habilitations: ['CPT:MODIFICATION', 'CTC:MODIFICATION', 'DPC:MODIFICATION', 'DPS:MODIFICATION', 'ETA:MODIFICATION', 'STA:CONSULTATION'],
    roles: [
      'ROLE_IE3_CONSULTATION_SUPPORTS',
      'ROLE_IE3_CONSULTATION_NOTIFICATIONS',
      'ROLE_IE3_CONSULTATION_THEMATIQUE',
      'ROLE_IE3_CONSULTATION_UTILISATEURS',
      'ROLE_IE3_MODIFICATION_UTILISATEUR',
      'ROLE_IE3_CONSULTATION_HABILITATIONS_PAR_DEFAUT',
      'ROLE_IE3_CREATION_UTILISATEUR',
      'ROLE_IE3_VALIDATION_CGU',
      'ROLE_IE3_RENOUVELLEMENT_DROITS_UTILISATEUR',
      'ROLE_IE3_RENVOI_COURRIEL_ACTIVATION_UTILISATEUR',
      'ROLE_IE3_SUPPRESSION_UTILISATEUR',
      'ROLE_IE3_EXPIRATION_DROITS_UTILISATEUR',
      'ROLE_IE3_ANNULATION_INSCRIPTION',
      'ROLE_IE3_CONSULTATION_ETABLISSEMENTS',
      'ROLE_IE3_MODIFICATION_ETABLISSEMENT',
      'ROLE_IE3_CHANGEMENT_ETABLISSEMENT'
    ],
    fondsEmployeur: 'IOP',
    libelleCourt: 'CCM',
    numContratEtablissement: 'A0000AAB'
  };

  endUserDetailsEmployeurEnDelegation = {
    identifiant: 'ADH000001',
    nom: 'Dhieb',
    prenom: 'Ahmed',
    civilite: 'MONSIEUR',
    email: 'ahmed.dhieb@cdc.fr',
    telephoneFixe: '0512345678',
    siretEmployeur: '26170046200011',
    bcrEmployeurTitulaire: '01BRG623',
    bcrEmployeurEnDelegation: '01AJG708',
    designationEmployeurTitulaire: 'CENTRE DE GESTION DE LA FONCTION PUBLIQUE TERRITORIALE DE LA CHARENTE MARITIME',
    designationEmployeurEnDelegation: 'CCAS DE LA COUARDE SUR MER',
    profilEmployeurTitulaire: ProfilEmployeurEnum.INTERMEDIAIRE,
    service: 'DEI',
    etat: 'ACTIF',
    profil: 'ADMINISTRATEUR',
    etatDelegation: EtatDelegationEnum.NON_AUTORISEE,
    habilitations: [
      'DAV:CONSULTATION',
      'FC3:CONSULTATION',
      'SRV:CONSULTATION',
      'AEM:CONSULTATION',
      'EDI:CONSULTATION',
      'NEC:CONSULTATION',
      'DIE:CONSULTATION',
      'COR:CONSULTATION',
      'DM2:CONSULTATION',
      'SCA:CONSULTATION',
      'AID:CONSULTATION',
      'LR6:CONSULTATION',
      'GC9:CONSULTATION',
      'AEA:CONSULTATION',
      'MOD:CONSULTATION',
      'QCI:CONSULTATION',
      'CMA:CONSULTATION',
      'AED:CONSULTATION',
      'AE9:CONSULTATION',
      'PLI:CONSULTATION',
      'RPS:CONSULTATION',
      'DM1:CONSULTATION',
      'DAI:CONSULTATION',
      'DEC:CONSULTATION',
      'DX1:CONSULTATION',
      'DX3:CONSULTATION',
      'CX3:CONSULTATION',
      'CX1:CONSULTATION'
    ],
    roles: [
      'ROLE_IE3_CHANGEMENT_ETABLISSEMENT',
      'ROLE_IE3_CONSULTATION_NOTIFICATIONS',
      'ROLE_IE3_CONSULTATION_SUPPORTS',
      'ROLE_IE3_CONSULTATION_THEMATIQUE',
      'ROLE_IE3_CONSULTATION_HABILITATIONS_PAR_DEFAUT',
      'ROLE_IE3_CONSULTATION_UTILISATEURS',
      'ROLE_IE3_RENOUVELLEMENT_DROITS_UTILISATEUR',
      'ROLE_IE3_SUPPRESSION_UTILISATEUR',
      'ROLE_PE3_EXPIRATION_DROITS_UTILISATEUR',
      'ROLE_PE3_MODIFICATION_UTILISATEUR',
      'ROLE_PE3_CREATION_UTILISATEUR',
      'ROLE_PE3_RENVOI_COURRIEL_ACTIVATION_UTILISATEUR',
      'ROLE_PE3_ANNULATION_INSCRIPTION',
      'ROLE_PE3_CONSULTATION_ETABLISSEMENTS'
    ]
  };

  constructor(private readonly _localStoreManager: LocalStoreManager) {
    super();
  }

  public initializeOauthConfiguration(
    oauthConfig: OAuthConfig,
    userProfileConfig: UserProfileConfig,
    tokenValidationHandler?: ValidationHandler
  ): void {
    this.userProfileConfig = userProfileConfig;
  }

  reconfigure(oauthConfig: OAuthConfig): void {}

  public onErrorEvents(): Observable<OAuthEvent> {
    return EMPTY;
  }

  public tryOauth(options?: LoginOptions): Promise<boolean> {
    return Promise.resolve(true);
  }

  public initLoginFlow(): void {}

  public getAccessToken(): string {
    return 'FAKE_ACCESS_TOKEN';
  }

  loadConnectedUser(): Promise<any> {
    this._user = SecurityUser.from(this.endUserDetailsEmployeurTitulaire);
    return Promise.resolve(this._user);
  }

  refreshUtilisateurConnectePourEmployeurEnDelegation(bcrEmployeurCible: string): Promise<SecurityUser> {
    this._localStoreManager.setItem(Constantes.STORAGE_KEY_BCR_EMPLOYEUR_CIBLE, bcrEmployeurCible);
    this._user = SecurityUser.from(this.endUserDetailsEmployeurEnDelegation);
    return Promise.resolve(this._user);
  }

  refreshUtilisateurConnectePourEmployeurTitulaire(): Promise<SecurityUser> {
    this._localStoreManager.removeItem(Constantes.STORAGE_KEY_BCR_EMPLOYEUR_CIBLE);
    this._user = SecurityUser.from(this.endUserDetailsEmployeurTitulaire);
    return Promise.resolve(this._user);
  }

  public logout(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  public isUserAuthenticated(): boolean {
    return true;
  }

  public estHabilite(habilitation: string): boolean {
    return this._user && this._user.estHabilite(habilitation);
  }

  setupAutomaticSilentRefresh(): void {}

  refreshToken(): void {}

  onAccessTokenExpireEvent(): Observable<OAuthEvent> {
    return EMPTY;
  }
}
