export abstract class RegexPatterns {
  // Cette regexp mail correspond à celle utilisée par le Back-end = elles doivent être identiques pour ne pas générer des erreurs différentes.
  static readonly MAIL = '^[\\w-]+(?:\\.[\\w-]+)*@(?:[\\w-]+\\.)+[\\w]{2,25}$';

  static readonly NUM_CONTRAT = '^[A-Z]{1}[0-9]{4}[A-Z]{3}$';

  static readonly SIRET = '[0-9]{14}';

  static readonly CODE_POSTAL = '^[0-9]{5}$';

  static readonly TELEPHONE_PRO = '^0[1-9][0-9]{8}$';

  static readonly NOM = "^[A-Za-zÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝŸàáâãäåçèéêëìíîïñòóôõöùúûüýÿ ')(\\-]{1,30}$";

  static readonly PRENOM = "^[A-Za-zÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝŸàáâãäåçèéêëìíîïñòóôõöùúûüýÿ ')(\\-]{1,30}$";

  static readonly VILLE = "^[0-9A-Za-záÁàÀâÂäÄãÃçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕùÙûÛüÜÿ'\\- ]{0,70}$";

  static readonly CHAMPS_LIBRE = "^[0-9A-Za-záÁàÀâÂäÄãÃçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕùÙûÛüÜÿ'\\-_,/°(). ]{0,70}$";

  static readonly ALPHA_NUMERIC_PATTERN = /[^0-9a-zA-Z]/g;

  static readonly RAISON_SOCIALE = "^[A-Za-z0-9ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝŸàáâãäåçèéêëìíîïñòóôõöùúûüýÿ '@&-.()]{1,90}$";

  static readonly LIBELLE_COURT = "^[A-Za-z0-9ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝŸàáâãäåçèéêëìíîïñòóôõöùúûüýÿ '@&-.()]{1,20}$";
}
