import {Injectable} from '@angular/core';
import {EnvironmentConfig} from './environment-config';
import {VanillaHttpService} from '@shared/services/vanilla-http.service';
import {ObjectUtils} from '@shared/utils';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  public config: EnvironmentConfig;

  constructor(
    private readonly _vanillaHttpService: VanillaHttpService
  ) {
  }

  load() {
    if (this.config) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      // On utilise pas HttpClient car il n'est pas encore initialisé et nous requêtons une url locale non sécurisée
      this._vanillaHttpService.httpGet(environment.CONTEXT_FILE_PATH)
        .subscribe(data => {
            // utile pour les devs (fichier context-local.json)
            // if (environment.CONTEXT_LOCAL_FILE_PATH) {
            //   this._vanillaHttpService.httpGet(environment.CONTEXT_LOCAL_FILE_PATH)
            //     .subscribe(dataLocal => {
            //         this._setConfig(resolve, data, dataLocal);
            //       },
            //       () => {
            //         this._setConfig(resolve, data, null);
            //       });
            // } else {
              this._setConfig(resolve, data, null);
            // }
          },
          e => {
            console.error('Error while loading context file');
            reject(e);
          });
    });
  }

  private _setConfig(resolve: (value?: boolean | PromiseLike<boolean>) => void, config: any, configLocal: any = {}) {
    this.config = ObjectUtils.mergeDeep(config, configLocal);
    resolve(true);
  }
}
