import {Injectable} from '@angular/core';
import {SecurityService} from 'security/service/oauth-security.service';
import {GenericCacheService} from './generic-cache.service';

export enum KeyCacheEnum {
  THEMATIQUES = 'THEMATIQUES',
  ACTUALITES = 'ACTUALITES'
}

@Injectable({
  providedIn: 'root'
})
export class SimpleCacheService extends GenericCacheService<any> {

  constructor(private readonly _securityService: SecurityService) {
    super();
  }

  public getCachedDataPourEmployeurCible(keyCache: KeyCacheEnum, dataToReturnIfNotPresent: any = null): any {
    const bcrEmployeurCible = this._securityService.getConnectedUser().bcrEmployeurCible;
    return this.getCachedData(keyCache + '_' + bcrEmployeurCible, dataToReturnIfNotPresent);
  }

  public setCachedDataPourEmployeurCible(keyCache: KeyCacheEnum, data: any): any {
    const bcrEmployeurCible = this._securityService.getConnectedUser().bcrEmployeurCible;
    this.setCachedData(keyCache + '_' + bcrEmployeurCible, data);
  }

  public clearCaches() {
    this._caches.forEach((value, key, map) => {
      if (key.startsWith(KeyCacheEnum.THEMATIQUES)) {
        map.delete(key);
      }
    });
  }

}
