import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Endpoint } from '@shared/endpoints';
import { ObjectUtils } from '@shared/utils';
import {
  CRASH,
  JETON_DEJA_UTILISE,
  JETON_EXPIRE,
  JETON_NON_TROUVE,
  JETON_NOUVELLE_DEMANDE_EN_COURS,
  MOT_DE_PASSE_INVALIDE,
  MotDePasseErreurServeurModel
} from '@public/modules/mot-de-passe/models/mot-de-passe-erreur-serveur.model';
import { Action, JetonLienActionModel } from '@public/modules/mot-de-passe/models/jeton-lien-action.model';
import { SecurityService } from 'security/service/oauth-security.service';
import { Router } from '@angular/router';
import { CompteBloqueModalComponent } from '@shared/components/compte-bloque-modal/compte-bloque-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStoreManager } from 'boot/service/local-store-manager.service';
import { ContactAdministrateurModel } from '../models/contact-administrateur.model';
import { Constantes } from 'boot/constantes';

@Injectable({
  providedIn: 'root'
})
export class MotDePasseService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _router: Router,
    private readonly _securityService: SecurityService,
    private readonly _modalService: NgbModal,
    private readonly _localStoreManager: LocalStoreManager
  ) {}

  regeneration(idUtilisateur: string, mailUtilisateur: string): Observable<void> {
    return this._httpClient.post<void>(Endpoint.GENERATION_MOT_DE_PASSE, {
      identifiantUtilisateur: idUtilisateur,
      emailUtilisateur: mailUtilisateur
    });
  }

  /**
   * Vérification auprès du WS de la validité du jeton
   * @param jetonLienAction {JetonLienActionModel} jeton à vérifier
   */
  controleLienRegenerationMotDePasse(jetonLienAction: JetonLienActionModel): Observable<JetonLienActionModel> {
    if (
      !jetonLienAction ||
      ObjectUtils.stringIsNullOrEmpty(jetonLienAction.jeton) ||
      ObjectUtils.stringIsNullOrEmpty(jetonLienAction.action)
    ) {
      return throwError(JETON_NON_TROUVE);
    }

    return new Observable<JetonLienActionModel>(subscriber => {
      this._httpClient.get(`${Endpoint.CONTROLE_VALIDITE_JETON}/${jetonLienAction.action}/${jetonLienAction.jeton}`).subscribe(
        () => {
          subscriber.next(jetonLienAction);
          subscriber.complete();
        },
        response => {
          if (!response.error || !response.error.type) {
            subscriber.error(CRASH);
          } else {
            subscriber.error(this._getErreurCorrespondante(response.error.type));
          }
          subscriber.complete();
        }
      );
    });
  }

  changerMotDePasseAvecJeton(motDePasse: string, jetonLienAction: JetonLienActionModel): Observable<void> {
    return new Observable<void>(subscriber => {
      const body = {
        motDePasse,
        jeton: jetonLienAction.jeton
      };
      const url =
        jetonLienAction.action === Action.ACTIVATION_COMPTE_UTILISATEUR
          ? Endpoint.INITIALISER_MOT_DE_PASSE
          : Endpoint.CHANGER_MOT_DE_PASSE_AVEC_JETON;
      this._httpClient.post(url, body).subscribe(
        () => {
          subscriber.next();
          subscriber.complete();
        },
        response => {
          subscriber.error(response);
          subscriber.complete();
        }
      );
    });
  }

  changerMotDePasse(motDePasseActuel: string, nouveauMotDePasse: string): Observable<void> {
    return new Observable<void>(subscriber => {
      const connectedUser = this._securityService.getConnectedUser();
      const body = {
        motDePasseActuel,
        nouveauMotDePasse
      };
      const url = Endpoint.CHANGER_MOT_DE_PASSE.replace('{bcr}', connectedUser.bcrEmployeurCible).replace(
        '{identifiant}',
        connectedUser.identifiant
      );
      this._httpClient.post(url, body).subscribe(
        () => {
          subscriber.next();
          subscriber.complete();
        },
        response => {
          // compte bloqué
          if (response.status === 423) {
            this._localStoreManager.setItem(Constantes.STORAGE_KEY_COMPTE_BLOQUE, 'true');
            this._ouvrirModalCompteBloque();
          }
          subscriber.error(response);
          subscriber.complete();
        }
      );
    });
  }

  private _ouvrirModalCompteBloque() {
    this._modalService.open(CompteBloqueModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  /**
   * Retourne l'erreur {@link MotDePasseErreurServeurModel} correspondante au code {@code wsTypeErreur}
   * @param wsTypeErreur code d'erreur venant du WS
   *
   * @return erreur correspondante ou {@link CRASH}
   */
  private _getErreurCorrespondante(wsTypeErreur): MotDePasseErreurServeurModel {
    const all = [JETON_NON_TROUVE, JETON_DEJA_UTILISE, JETON_NOUVELLE_DEMANDE_EN_COURS, JETON_EXPIRE, MOT_DE_PASSE_INVALIDE];

    return all.find(a => a.typeErreur === wsTypeErreur) || CRASH;
  }

  recupererContactsAdministrateursDepuisJeton(jeton: JetonLienActionModel): Observable<ContactAdministrateurModel[]> {
    const url = Endpoint.RECUPERER_ADMINS_DEPUIS_LIEN_CREATION_MOT_DE_PASSE.replace('{action}', jeton.action).replace(
      '{jeton}',
      jeton.jeton
    );
    return this._httpClient.get<ContactAdministrateurModel[]>(url);
  }
}
