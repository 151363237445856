import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpResponseService {

  constructor(
    private readonly _router: Router
  ) {
  }

  /**
   * Redirige l'utilisateur au besoin, en fonction du code de
   * la ou les réponses présentes dans {@code reponses}
   *
   * Renvoie true si une redirection est faite, sinon false
   *
   * @param reponses la ou les reponses http
   */
  public redirigerSiBesoin(reponses: any): boolean {
    if (!reponses) {
      return false;
    }

    if (Array.isArray(reponses)) {
      for (const reponse of reponses) {
        if (this.analyserEtRedirigerSiBesoin(reponse)) {
          return true;
        }
      }
    } else {
      return this.analyserEtRedirigerSiBesoin(reponses);
    }

    return false;
  }

  /**
   * Redirige l'utilisateur au besoin, en fonction du code de
   * la {@code reponses}
   *
   * Renvoie true si une redirection est faite, sinon false
   *
   * @param reponse la reponse http
   */
  private analyserEtRedirigerSiBesoin(reponse: any): boolean {
    if (!reponse) {
      return false;
    }

    const status = reponse.status;
    if (isNaN(status) || (status > 0 && status < 400)) {
      return false;
    }

    if (status === 401 || status === 403) {
      this._router.navigateByUrl('/erreurs/non-autorise');
    } else if (status === 404) {
      this._router.navigateByUrl('/erreurs/non-trouve');
    } else {
      this._router.navigateByUrl('/erreurs/non-disponible');
    }
    return true;
  }
}
