import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {delay, filter, skip} from 'rxjs/operators';
import {EnvironmentService} from '../context/environment.service';
import {MatomoConfig} from './matomoConfig';
import {UUID} from 'angular2-uuid';

declare var window;

export const matomoErrors = {
  disabled: new Error('Matomo est désactivé'),
  badConfig: new Error('Problème de configuration matomo : configuration incorrecte'),
  alreadyInit: new Error('Matomo a déjà été initialisé')
};

@Injectable({
  providedIn: 'root'
})
export class MatomoService {

  config: MatomoConfig;

  private readonly _storageKey = 'matomoUserId';
  private readonly _uniqueUserId: string;
  private _initialized: boolean;

  constructor(private readonly _router: Router,
              private readonly _environmentService: EnvironmentService) {

    this.config = new MatomoConfig(this._environmentService.config.matomo);
    this._uniqueUserId = this._getUniqueUserId(UUID.UUID());
    window._paq = [];
    this._initialized = false;
  }

  init() {
    try {
      if (!this._initialized) {
        if (this.config.peutEtreActive()) {
          this._configureTracker();
          this._matomoApi('requireConsent');
          this._trackPage();
          this._routerListener();
          this._initialized = true;
        } else if (!this.config.active) {
          throw matomoErrors.disabled;
        } else {
          throw matomoErrors.badConfig;
        }
      } else {
        throw matomoErrors.alreadyInit;
      }
    } catch (error) {
      console.log(error);
    }
  }

  giveConsent() {
    if (this._initialized) {
      this._matomoApi('rememberConsentGiven');
    }
  }

  removeConsent() {
    if (this._initialized) {
      this._matomoApi('forgetConsentGiven');
    }
  }

  private _trackPage(referrer?: string) {
    this._matomoApi('setCustomUrl', window.location.href);

    // Rajouter le documentTitle ?
    // this._matomoApi('setDocumentTitle', '');

    if (referrer) {
      this._matomoApi('setReferrerUrl', referrer);
    }
    this._matomoApi('trackPageView');
  }

  private _routerListener() {
    let referrer: string = window.location.href;

    this._router.events
      .pipe(
        // pour n'avoir que les events de fin de changement de route, et éviter les NavigationStart, Resolver, etc.
        filter(e => e instanceof NavigationEnd),
        // on saute le premier event de fin de changement de route, car on veut le traiter au init()
        skip(1),
        // utilisé dans angulartics2, mais pas eu le temps de regarder pourquoi... A vérifier avant de supprimer
        delay(0)
      )
      .subscribe(() => {
        this._trackPage(referrer);
        referrer = window.location.href;
      });
  }

  private _configureTracker() {
    this._matomoApi('setUserId', this._uniqueUserId);
    this._matomoApi('setSiteId', this.config.siteId);
  }

  private _matomoApi(command: string, value?: any) {
    const params = [command];
    if (value) {
      params.push(value);
    }
    window._paq.push(params);
  }

  /**
   * Représente un utilisateur (username / email / UUID). Doit être le même pour un utilisateur
   * à travers les pages / onglets
   *
   * @param uniqueUserId
   */
  private _getUniqueUserId(uniqueUserId: string): string {
    let uniqueId: string = localStorage.getItem(this._storageKey);
    if (!uniqueId) {
      uniqueId = uniqueUserId;
      localStorage.setItem(this._storageKey, uniqueId);
    }
    return uniqueId;
  }
}
