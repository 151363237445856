/**
 * Liste des endpoints du backend ie3-webservice-plateforme utilisés.
 */
export enum Endpoint {
  IDENTIFICATION_EMPLOYEUR = '/api/v1/public/inscription/identification',
  CREATION_ADMINISTRATEUR_PRINCIPAL = '/api/v1/public/inscription/creation',
  RENVOI_COURRIEL_ACTIVATION_COMPTE = '/api/v1/public/activation/renvoi-mail',
  ADMINISTRATEUR_PRINCIPAL_PAR_JETON = '/api/v1/public/inscription/administrateur/{jeton}/{action}',
  GENERATION_MOT_DE_PASSE = '/api/v1/public/regeneration-mot-de-passe/demande-regeneration',
  INITIALISER_MOT_DE_PASSE = '/api/v1/public/activation/initialisation-mot-de-passe',
  CHANGER_MOT_DE_PASSE_AVEC_JETON = '/api/v1/public/regeneration-mot-de-passe/changement',
  CHANGER_MOT_DE_PASSE = '/api/v1/private/employeurs/{bcr}/utilisateurs/{identifiant}/mot-de-passe',
  RECUPERATION_IDENTIFIANT = '/api/v1/public/recuperation-identifiant',
  CONTROLE_VALIDITE_JETON = '/api/v1/public/jeton-lien-action',
  RECUPERER_ADMINS_DEPUIS_LIEN_CREATION_MOT_DE_PASSE = '/api/v1/public/jeton-lien-action/{action}/{jeton}/administrateurs',
  RECUPERER_UTILISATEUR_DEPUIS_JETON = '/api/v1/public/jeton-lien-action/{action}/{jeton}/utilisateur',
  CAPTCHA = '/api/v1/public/captcha',
  HABILITATIONS = '/api/v1/private/habilitations-attribuables',
  SUPPORTS = '/api/v1/private/supports/{typeSupport}',
  EVENEMENTS = '/api/v1/private/evenements',
  TYPES_EVENEMENTS = '/api/v1/private/types-evenement',
  SERVICES_THEMATISES = '/api/v1/private/services',
  FONDS = '/api/v1/private/fonds',
  END_USER_DETAILS = '/api/v1/private/end-user-details',
  VALIDATION_INSCRIPTION_ADMINISTRATEUR = '/api/v1/public/inscription/validation',
  REGENERATION_VALIDATION_ADMINISTRATEUR = '/api/v1/public/inscription/demande-regeneration',
  PARAMETRES = '/api/v1/public/parametres'
}
