<form>
  <div class="modal-header">
    <h1 class="modal-title">{{'compte.modalCompteBloque.titre' | translate}}</h1>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-12">
        <ie3-alert>{{'compte.modalCompteBloque.messageAlerte' | translate}}</ie3-alert>
      </div>
    </div>
    <div [innerHTML]="'compte.modalCompteBloque.message' | translate"></div>
  </div>
  <div class="modal-footer">
    <div class="ms-0 me-0">
      <ie3-button (clicked)="deconnexion()"
                  [block]="true"
                  color="primary"
                  size="lg">{{'compte.modalCompteBloque.bouton' | translate}}</ie3-button>
    </div>
  </div>
</form>
