import { Injectable, OnDestroy } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export abstract class AbstractComponent implements OnDestroy {
  protected _unsubscribe: Subject<void> = new Subject();

  // Permet la gestion de m'unsubscribe sur n'importe quel Observable à la destruction du composant
  // en ajoutant ".pipe(takeUntil(this._unsubscribe))" avant le subscribe (memory leak)
  // Ne pas oublier d'importer la méthode takeUntil avec import { takeUntil } from 'rxjs/operators';
  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
