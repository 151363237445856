/** @author Maelig GOHIN*/
import {ObjectUtils} from '@shared/utils/object.utils';

export namespace ArrayDiffResult {
  export class Commons<T> {
    public oldItems: T[] = [];
    public newItems: T[] = [];
  }

  export class Results<T> {
    public added: T[] = [];
    public removed: T[] = [];
    public common: ArrayDiffResult.Commons<T> = new ArrayDiffResult.Commons<T>();
  }
}

// @dynamic
export class ArrayUtils {

  /**
   * Will filter the {@code array} with {@code search} criteria on all fields enumerated in [@code fields]
   * @param array array to filter
   * @param search search, will be normalized
   * @param fields every field to search on
   *
   * {@code fields} accepts dot annotations. Can go as deeply as you want in object, can although
   * go through arrays, check example below.
   *
   * // simple dummy object
   * class CarOption {
   *     type: any;
   *     name: any;
   * }
   *
   * class Car {
   *     brand: CarBrand;
   *     model: CarModel;
   *     tires: CarOption[];
   *     engine: CarEngine;
   *     color: string;
   * }
   *
   * You can filter an array of cars like :
   * filter(cars, 'bwm', ['brand.name', 'model.name', 'engine.model.name', 'engine.model.parts.pistons.type.code', 'tires.brand.name'])
   * filter(cars, 'blue', ['brand.name', 'model.name', 'color'])
   *
   */
  public static filter(array: any[] = [], search: string = null, fields: string[] = []): any[] {
    if (!array.length || ObjectUtils.stringIsNullOrEmpty(search) || !fields || !fields.length) {
      return Object.assign([], array);
    }

    const normalizedSearch = this.normalize(search);
    return array
      .filter(item => {
        // check if any property matches search
        for (const fullProperty of fields) {
          if (fullProperty == null || !fullProperty.trim().length) {
            continue;
          }

          const propertySplit = fullProperty.split('.');

          // no dot annotation, treat it right now
          if (propertySplit.length === 1) {
            if (this.equals(item[propertySplit[0]], normalizedSearch)) {
              return true;
            }
            continue;
          }

          // go through item with dot annotation properties
          let obj = item[propertySplit[0]];
          for (let j = 1; j < propertySplit.length; j++) {
            const property = propertySplit[j];
            if (obj !== undefined && obj !== null) {
              obj = obj[property];
            }

            // handle arrays
            if (Array.isArray(obj)) {
              if (this.filter(obj, search, propertySplit.slice(j + 1, propertySplit.length)).length > 0) {
                return true;
              }
              break;
            }

            // check if match
            if (this.equals(obj, normalizedSearch)) {
              return true;
            }
          }
        }

        return false;
      });
  }

  public static flatten(arr: any[]): any[] {
    return [].concat(...arr);
  }

  public static removeDuplicates(arr: any[]): any[] {
    const s = new Set(arr);
    const it = s.values();
    return Array.from(it);
  }

  public static flattenAndRemoveDuplicates(arr: any[]): any[] {
    return this.removeDuplicates(this.flatten(arr));
  }

  /**
   * Fait la différence entre deux array
   *
   * @param oldArray {T[]} ancienne version de l'array
   * @param newArray {T[]} nouvelle version de l'array
   * @param comparator {(a: T, b: T) => boolean} comparateur de 2 objets de type T
   */
  public static diff<T>(oldArray: T[], newArray: T[], comparator: (a: T, b: T) => boolean): ArrayDiffResult.Results<T> {
    const diffResult = new ArrayDiffResult.Results<T>();

    if ((!oldArray || !oldArray.length) && (!newArray && !newArray.length)) {
      return diffResult;
    }

    if (!oldArray || !oldArray.length) {
      diffResult.added.push(...newArray);
      return diffResult;
    }

    if (!newArray || !newArray.length) {
      diffResult.removed.push(...oldArray);
      return diffResult;
    }

    // items in newArray but not in oldArray
    diffResult.added.push(...newArray
      .filter(o => !oldArray
        .some(n => comparator(o, n)))
    );

    // items in oldArray but not in newArray
    diffResult.removed.push(...oldArray
      .filter(o => !newArray
        .some(n => comparator(o, n)))
    );

    // items in both arrays
    diffResult.common.oldItems.push(...oldArray
      .filter(o => newArray
        .some(n => comparator(o, n)))
    );
    diffResult.common.newItems.push(...newArray
      .filter(o => oldArray
        .some(n => comparator(o, n)))
    );

    return diffResult;
  }

  private static equals(obj: any, search: string): boolean {
    try {
      return this.stringContains(this.normalize(obj.toString()), search);
    } catch (e) {
      return false;
    }
  }

  private static stringContains(incluses?: string, included?: string): boolean {
    return incluses !== undefined && incluses !== null && incluses.indexOf(included) > -1;
  }

  private static normalize(str?: string): string {
    if (str === undefined || str === null) {
      return null;
    }

    // FIXME : check review code ?!! c'est OK via Chrome, mais normalize('NFD') en erreur via IE11 ...
    // NB : pour pallier à cette erreur, l'installation d'un module "unorm" ferait "bricolage/bidouille"
    // https://stackoverflow.com/questions/54242799/ie11-object-doesnt-support-property-or-method-normalize
    // return str.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f\s]/g, '');
    return str.trim().toLowerCase().replace(/[\u0300-\u036f\s]/g, '');
  }
}
