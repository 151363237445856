import {TranslateLoader} from '@ngx-translate/core';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslationDataService} from 'boot/i18n/translation-data.service';
import {VanillaHttpService} from '@shared/services/vanilla-http.service';
import { environment } from '../../environments/environment.prod';

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private readonly _translationService: TranslationDataService,
    private readonly _vanillaHttpService: VanillaHttpService,
    private readonly _prefix = 'assets/i18n/') {
  }

  getTranslation(lang: string): Observable<any> {
    // On utilise pas HttpClient car il n'est pas encore initialisé et nous requêtons une url locale non sécurisée
    const requests = this._translationService.translationFiles.map(resource => {
      return this._vanillaHttpService.httpGet(`${this._prefix + lang}/${resource}?${environment.PACKAGE_VERSION}`);
    });

    return forkJoin(requests).pipe(map(response => response.reduce((a, b) => Object.assign(a, b))));
  }
}
