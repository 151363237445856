import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscriber} from 'rxjs';
import {EnvironmentService} from 'boot/context/environment.service';

declare var Ie3IhmCommun;
declare var Ie3IhmCommunBootstrap;
declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class IhmCommunService {

  private readonly initDoneObs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private initStatus: 'none' | 'in_progress' | 'done' = 'none';

  constructor(private readonly _environmentService: EnvironmentService) {
  }

  public init(initOptions: any): any {
    return new Observable<any>(subscriber => {
      Ie3IhmCommun.init(initOptions)
        .then(composants => {
          subscriber.next(composants);
          subscriber.complete();
        });
    });
  }

  public get(): BehaviorSubject<any> {
    if (this.initStatus === 'none') {
      this.initStatus = 'in_progress';
      this.loadScript()
        .subscribe(lib => {
          this.initStatus = 'done';
          this.initDoneObs.next(lib);
        });
    }

    return this.initDoneObs;
  }

  private scriptLoaded(subscriber: Subscriber<any>): void {
    Ie3IhmCommunBootstrap.bootstrap({polyfill: false, fonts: false}, () => {
      Ie3IhmCommun.init()
        .then(composants => {
          subscriber.next(composants);
          subscriber.complete();
        });
    });
  }

  private loadScript(): Observable<any> {
    return new Observable<any>(subscriber => {
      const scriptSrc = this._environmentService.config.scriptIhmCommunURL;

      // load script
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = scriptSrc;

      if (script.readyState) {  // IE
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            this.scriptLoaded(subscriber);
          }
        };
      } else {  // Others
        script.onload = () => {
          this.scriptLoaded(subscriber);
        };
      }

      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }
}
