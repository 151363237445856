import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {SecurityService} from './oauth-security.service';
import {Location} from '@angular/common';
import {EnvironmentService} from 'boot/context/environment.service';

@Injectable()
export class NoAuthGuard implements CanActivate {

  constructor(
    private readonly _securityService: SecurityService,
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _environmentService: EnvironmentService) {
  }

  canActivate() {
    return true;
  }
}
