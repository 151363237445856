import {Component, OnInit} from '@angular/core';
import {SecurityService} from 'security/service/oauth-security.service';

@Component({
  selector: 'ie3-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  afficherRetourTdb = false;
  afficherRetourLogin = false;
  private _scope: 'public' | 'private' = 'public';

  constructor(
    private readonly _securityService: SecurityService
  ) {
  }

  ngOnInit(): void {
    this._scope = this._securityService.getConnectedUser() ? 'private' : 'public';

    if (this._scope === 'private') {
      this.afficherRetourTdb = true;
    } else {
      this.afficherRetourLogin = true;
    }
  }
}
