import { ProfilUtilisateurEnum } from '@private/modules/utilisateurs/shared/models/profil-utilisateur.enum';
import { HabilitationsUtilisateurModel } from '@shared/models/utilisateur.habilitations.model';
import { ObjectUtils } from '@shared/utils';
import { DelegationInformationsModel } from 'security/entity/delegation-informations.model';

export enum EtatDelegationEnum {
  NON_AUTORISEE = 'NON_AUTORISEE',
  AUTORISEE = 'AUTORISEE',
  EN_COURS = 'EN_COURS',
  AUTORISABLE = 'AUTORISABLE'
}

export enum ProfilEmployeurEnum {
  STANDARD = 'STANDARD',
  INTERMEDIAIRE = 'INTERMEDIAIRE',
  CENTRALISATEUR = 'CENTRALISATEUR'
}

export class SecurityUser {
  identifiant: string;
  civilite: string;
  nom: string;
  prenom: string;
  email: string;
  telephoneFixe: string;
  siretEmployeur: string;
  bcrEmployeurTitulaire: string;
  bcrEmployeurEnDelegation: string;
  designationEmployeurTitulaire: string;
  designationEmployeurEnDelegation: string;
  profilEmployeurTitulaire: string;
  service: string;
  etat: string;
  profil: string;
  habilitations: HabilitationsUtilisateurModel[] = [];
  rolesPlateforme: string[] = [];
  etatDelegation: string;
  fondsEmployeur: string;
  libelleCourt: string;
  numContratEtablissement: string;

  public static from(endUserDetails: any): SecurityUser {
    const user = new SecurityUser();
    user.identifiant = endUserDetails.identifiant;
    user.civilite = endUserDetails.civilite;
    user.nom = endUserDetails.nom;
    user.prenom = endUserDetails.prenom;
    user.email = endUserDetails.email;
    user.telephoneFixe = endUserDetails.telephoneFixe;
    user.siretEmployeur = endUserDetails.siretEmployeur;
    user.bcrEmployeurTitulaire = endUserDetails.bcrEmployeurTitulaire;
    user.bcrEmployeurEnDelegation = endUserDetails.bcrEmployeurEnDelegation;
    user.designationEmployeurTitulaire = endUserDetails.designationEmployeurTitulaire;
    user.designationEmployeurEnDelegation = endUserDetails.designationEmployeurEnDelegation;
    user.profilEmployeurTitulaire = endUserDetails.profilEmployeurTitulaire;
    user.service = endUserDetails.service;
    user.etat = endUserDetails.etat;
    user.profil = endUserDetails.profil;
    user.etatDelegation = endUserDetails.etatDelegation;
    user.fondsEmployeur = endUserDetails.fondsEmployeur;

    endUserDetails.habilitations.forEach(h => {
      const habilitationArray: string[] = h.split(':');
      user.habilitations.push(new HabilitationsUtilisateurModel(habilitationArray[0], habilitationArray[1], h.perimetre));
    });
    user.rolesPlateforme = endUserDetails.roles.map((role: string) => role.replace(new RegExp('^ROLE_'), ''));
    user.libelleCourt = endUserDetails.libelleCourt;
    user.numContratEtablissement = endUserDetails.numContratEtablissement;

    return user;
  }

  public static fromStorage(objet: any) {
    const user = new SecurityUser();
    const keys = Object.keys(objet);
    keys.forEach(k => (user[k] = objet[k]));
    return user;
  }

  get bcrEmployeurCible() {
    return this.bcrEmployeurEnDelegation ? this.bcrEmployeurEnDelegation : this.bcrEmployeurTitulaire;
  }

  get designationEmployeurCible() {
    return this.designationEmployeurEnDelegation ? this.designationEmployeurEnDelegation : this.designationEmployeurTitulaire;
  }

  /**
   * Check if user has {@code right} in his list of rights
   *
   * @param right {string}
   *
   * @returns true if user has right, else false
   */
  public estHabilite(right: string): boolean {
    return (
      (!ObjectUtils.stringIsNullOrEmpty(right) || (Array.isArray(right) && right.length > 0)) &&
      (this.checkHabilitationUser(this.habilitations, right) || this.checkRolesUser(this.rolesPlateforme, right))
    );
  }

  public getDelegationInformations() {
    return new DelegationInformationsModel(this.etatDelegation, this.designationEmployeurCible);
  }

  public estAutorisableAFaireDeLaDelegation(): boolean {
    return this.etatDelegation === EtatDelegationEnum.AUTORISEE || this.etatDelegation === EtatDelegationEnum.AUTORISABLE;
  }

  public estAdministrateur(): boolean {
    return this.profil === ProfilUtilisateurEnum.ADMINISTRATEUR;
  }

  public estGestionnaire(): boolean {
    return this.profil === ProfilUtilisateurEnum.GESTIONNAIRE;
  }

  public estEnDelegation(): boolean {
    return this.etatDelegation === EtatDelegationEnum.EN_COURS;
  }

  public hasEmployeurTitulaireIntermediaire(): boolean {
    return this.profilEmployeurTitulaire === ProfilEmployeurEnum.INTERMEDIAIRE;
  }

  public hasEmployeurTitulaireCentralisateur(): boolean {
    return this.profilEmployeurTitulaire === ProfilEmployeurEnum.CENTRALISATEUR;
  }

  private checkHabilitationUser(habilitations: HabilitationsUtilisateurModel[], right: string) {
    return habilitations.some(habilitationUser => {
      const habilitationService = `${habilitationUser.service}:${habilitationUser.habilitation}`;
      return right.includes(habilitationService);
    });
  }

  private checkRolesUser(roles: string[], right: string): boolean {
    return roles.some(r => r === right);
  }
}
