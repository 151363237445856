import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SecurityService } from './oauth-security.service';
import { Observable } from 'rxjs';
import { ObjectUtils } from 'ie3-ihm-lib-ngx';

@Injectable()
export class HabilitationsGuard implements CanActivateChild {
  constructor(private readonly _router: Router, private readonly _securityService: SecurityService) {
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const habilitation = childRoute.data?.habilitation;

    if (this.checkDataHabilitationRoute(habilitation) && !this._securityService.estHabilite(habilitation)) {
      this._router.navigateByUrl('/erreurs/non-autorise');
      return false;
    }

    return true;
  }

  private checkDataHabilitationRoute(habilitation: string | string[]): boolean {
    return !ObjectUtils.stringIsNullOrEmpty(habilitation) || Array.isArray(habilitation) && habilitation.length > 0;
  }
}
