export class ParametrageModel {
  delaisExpirationCompteUtilisateurEnMois: number;
  dureeValiditeLienActivationCompteUtilisateurEnJour: number;
  dureeValiditeLienRegenerationMdpEnJour: number;
  dureeAccesRestreintAdminPrincipalEnJour: number;
  nombreActualitesSurTableauDeBord: number;
  dureeAvantQueCgusNonValideesBloquentConnexionEnJour: number;

  public static from(raw: any): ParametrageModel {
    const parametrageModel = new ParametrageModel();
    parametrageModel.delaisExpirationCompteUtilisateurEnMois = parseInt(raw.delaisExpirationCompteUtilisateurEnMois, 10);
    parametrageModel.dureeValiditeLienActivationCompteUtilisateurEnJour = parseInt(raw.dureeValiditeLienActivationCompteUtilisateurEnJour, 10);
    parametrageModel.dureeValiditeLienRegenerationMdpEnJour = parseInt(raw.dureeValiditeLienRegenerationMdpEnJour, 10);
    parametrageModel.dureeAccesRestreintAdminPrincipalEnJour = parseInt(raw.dureeAccesRestreintAdminPrincipalEnJour, 10);
    parametrageModel.nombreActualitesSurTableauDeBord = parseInt(raw.nombreActualitesSurTableauDeBord, 10);
    parametrageModel.dureeAvantQueCgusNonValideesBloquentConnexionEnJour = parseInt(raw.dureeAvantQueCgusNonValideesBloquentConnexionEnJour, 10);
    return parametrageModel;
  }
}
