import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { EnvironmentService } from '../context/environment.service';
import { timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SecurityService } from 'security/service/oauth-security.service';
import { LocalStoreManager } from 'boot/service/local-store-manager.service';
import { Constantes } from 'boot/constantes';

@Injectable()
export class CommonHttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private readonly _environmentService: EnvironmentService,
    private readonly _securityService: SecurityService,
    private readonly _localStoreManager: LocalStoreManager
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newUrl = this.makeURL(req.url);
    req = req.clone({
      url: newUrl,
      headers: this.getRequestHeaders(req, newUrl)
    });

    const time = this.isDownloadFileRequest(req) ? this._environmentService.config.backendDownloadTimeout : this._environmentService.config.backendTimeout;

    return next
      .handle(req)
      .pipe(timeout(time));
  }

  private makeURL(url: string): string {
    const backendURLPrivate = this._environmentService.config.backendURLPrivate;
    const backendURLPublic = this._environmentService.config.backendURLPublic;

    if (this.isUrlNotBackendUrlOrHttp(url, backendURLPrivate) || this.isUrlNotBackendUrlOrHttp(url, backendURLPublic)) {
      return url;
    }
    return this.changeUrlToBackendUrl(url, backendURLPrivate, backendURLPublic);
  }

  private isUrlNotBackendUrlOrHttp(url: string, backendURL: string): boolean {
    return url.indexOf(backendURL) === 0 || url.indexOf('http') === 0;
  }

  private changeUrlToBackendUrl(url: string, backendURLPrivate: string, backendURLPublic: string): string {
    if (url.includes('api/v1/private')) {
      return backendURLPrivate + url;
    }
    return backendURLPublic + url;
  }

  private getRequestHeaders(req: HttpRequest<any>, newUrl: string): HttpHeaders {
    let httpHeaders = req.headers || new HttpHeaders();

    httpHeaders = this.headersDeBase(httpHeaders);

    httpHeaders = this.headersIE3(newUrl, httpHeaders);

    httpHeaders = this.headersSecurite(httpHeaders);

    httpHeaders = this.headersDelegation(httpHeaders);

    return httpHeaders;
  }

  private headersDeBase(httpHeaders: HttpHeaders) {
    if (!httpHeaders.has('Content-Type')) {
      httpHeaders = httpHeaders.set('Content-Type', 'application/json;charset=UTF-8');
    }
    return httpHeaders;
  }

  private headersIE3(newUrl: string, httpHeaders: HttpHeaders) {
    if (newUrl.includes(this._environmentService.config.backendURLPrivate)) {
      httpHeaders = httpHeaders.set('X-RequestID', UUID.UUID());
    }
    return httpHeaders;
  }

  private headersSecurite(httpHeaders: HttpHeaders): HttpHeaders {
    if (this._securityService.isUserAuthenticated()) {
      httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + this._securityService.getAccessToken());
    }
    return httpHeaders;
  }

  private headersDelegation(httpHeaders: HttpHeaders): HttpHeaders {

    const bcrEmployeurCible = this._localStoreManager.getItem(Constantes.STORAGE_KEY_BCR_EMPLOYEUR_CIBLE) ||
      this._securityService.getConnectedUser()?.bcrEmployeurTitulaire;

    if (bcrEmployeurCible && this._securityService.isUserAuthenticated()) {
      httpHeaders = httpHeaders.set('X-BcrEmployeurCible', bcrEmployeurCible);
    }
    return httpHeaders;
  }

  private isDownloadFileRequest(req: HttpRequest<any>): boolean {
    return req.responseType === 'blob';
  }
}
