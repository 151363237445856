import {LoginOptions, OAuthEvent, ValidationHandler} from 'angular-oauth2-oidc';
import {OAuthConfig} from '../config/oauthConfig';
import {UserProfileConfig} from '../config/user-profile.config';
import {Observable} from 'rxjs';
import {SecurityUser} from 'security/entity/security-user.model';

export abstract class SecurityService {

  protected _user: SecurityUser;
  protected userProfileConfig: UserProfileConfig;

  public abstract initializeOauthConfiguration(
    oauthConfig: OAuthConfig,
    userProfileConfig: UserProfileConfig,
    tokenValidationHandler?: ValidationHandler
  ): void;

  public abstract reconfigure(oauthConfig: OAuthConfig): void;

  public abstract onErrorEvents(): Observable<OAuthEvent>;

  public abstract onAccessTokenExpireEvent(): Observable<OAuthEvent>;

  public abstract tryOauth(options?: LoginOptions): Promise<boolean>;

  public abstract getAccessToken(): string;

  public abstract loadConnectedUser(): Promise<SecurityUser>;

  public abstract logout(): void;

  public abstract isUserAuthenticated(): boolean;

  public abstract initLoginFlow(): void;

  public getConnectedUser(): SecurityUser {
    return this._user;
  }

  public abstract refreshUtilisateurConnectePourEmployeurTitulaire(): Promise<SecurityUser>;

  public abstract refreshUtilisateurConnectePourEmployeurEnDelegation(bcrEmployeurCible: string): Promise<SecurityUser>;

  public abstract estHabilite(habilitation: string): boolean;

  public abstract setupAutomaticSilentRefresh(): void;

  public abstract refreshToken(): void;
}
