import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractComponent} from '@shared/components/abstract.component';
import {CaptchaModel} from '@shared/models/captcha/captcha.model';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CaptchaAnswer} from '@shared/components/captcha/captcha-input.component';
import {ObjectUtils} from '@shared/utils';

@Component({
  selector: 'ie3-captcha-input-audio',
  styleUrls: ['./captcha-input-audio.component.scss'],
  template: `
      <div>
        <span class="sr-only">{{'captcha.message.audio' | translate}}</span>
          <figure>
              <audio #audio controls [src]="'data:audio/mp3;base64,'+captcha?.sound.items[0].value">
                  {{ 'captcha.audioNotSupported.label1' | translate }}
              </audio>
          </figure>
        <div class="d-flex flex-column align-items-start">
            <label for="inputAudio">{{ 'captcha.audioPlaceholder' | translate }}</label>
            <input type="text" class="form-control"
                 id="inputAudio"
                 [ngClass]="{'is-valid' : captcha.answerIsCorrect}"
                 [(ngModel)]="audioResponse"
                 #soundInput="ngModel"
                 (blur)="sendResponse()"
                 (keyup.enter)="sendResponse()"
                 [attr.disabled]="captcha.hasBeenAnswered === true ? 'disabled' : null">
        </div>
      </div>`
})
export class CaptchaInputAudioComponent extends AbstractComponent implements OnInit {

  @Input()
  captcha: CaptchaModel;

  @Input()
  reset: Observable<void>;

  @Output()
  updateResponse = new EventEmitter<CaptchaAnswer>();

  @ViewChild('audio', {static: true}) audio: ElementRef;

  audioResponse = '';

  ngOnInit(): void {
    this.audio.nativeElement.focus();
    if (this.reset) {
      this.reset
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(() => this.audioResponse = null);
    }
  }

  sendResponse() {
    if (!ObjectUtils.stringIsNullOrEmpty(this.audioResponse.trim())) {
      this.updateResponse.emit({
        question: this.audioResponse.trim(),
        answer: this.captcha.sound.items[0].hash
      });
    }
  }
}
