import {Component, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'ie3-route-loading',
  template: `
    <div *ngIf="visible" class="progressbar-routing" role="progressbar" aria-label="Chargement en cours" [attr.aria-busy]="visible">
    </div>
  `
})
export class LoadingComponent {

  @Input() visible = false;

  constructor() {}

}
