import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractComponent} from '@shared/components/abstract.component';
import {CaptchaModel} from '@shared/models/captcha/captcha.model';
import {CaptchaAnswer} from '@shared/components/captcha/captcha-input.component';

@Component({
  selector: 'ie3-captcha-input-image',
  styleUrls: ['./captcha-input-image.component.scss'],
  template: `
    <div class="ie3-captcha-input-image--consigne">
      <span>{{ 'captcha.consigneImage' | translate }}</span>&nbsp;<span
      class="ie3-captcha-input-image--consigne--question">{{captcha?.image.question}}</span>
    </div>
    <div class="ie3-captcha-input-image--images">
        <div (click)="onClick(img.hash)" tabindex="-1" class="ie3-captcha-input-image--images--item" *ngFor="let img of captcha?.image.items; let i = index">
          <label>
            <input type="radio" name="input-radio-captcha">
            <img [src]="'data:image/png;base64,' + img.value" alt="">
          </label>
        </div>
    </div>`
})
export class CaptchaInputImageComponent extends AbstractComponent {

  @Input()
  captcha: CaptchaModel;

  @Output()
  updateResponse = new EventEmitter<CaptchaAnswer>();

  onClick(hash: string) {
    this.updateResponse.emit({
      question: this.captcha.image.question,
      answer: hash
    });
  }
}
