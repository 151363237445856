<ie3-fil-ariane *ngIf="afficherHeaderPage" [titleOnly]="true"></ie3-fil-ariane>
<section class="section-main">
  <div class="container">
    <div class="d-flex justify-content-center">
      <img alt="" src="{{srcImagePage}}">
    </div>
    <div class="d-flex justify-content-center">
      <p class="ie3-error-font ie3-error-commentaire">
        {{commentaireI18nKey | translate}}
      </p>
    </div>
    <div *ngIf="afficherConsigne" class="d-flex justify-content-center">
      <p class="ie3-error-font ie3-error-consigne">
        {{consigneI18nKey  | translate}}
      </p>
    </div>
    <div *ngIf="afficherConsigneComplements" class="d-flex justify-content-center">
      <p class="ie3-error-font ie3-error-consigne">
        {{consigneComplementsI18nKey  | translate}}
      </p>
    </div>
    <div *ngIf="afficherRetourTdb || afficherRetourLogin" class="d-flex justify-content-center">
      <div class="d-flex justify-content-sm-start">
        <a *ngIf="afficherRetourTdb" class="btn btn-primary btn-lg"
           routerLink="/">
          {{retourTdBI18nKey  | translate}}
        </a>
        <a *ngIf="afficherRetourLogin" class="btn btn-primary btn-lg"
           routerLink="/">
          {{retourLoginI18nKey |  translate}}
        </a>
      </div>
    </div>
  </div>
</section>
