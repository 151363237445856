import {ValidationHandler, ValidationParams} from 'angular-oauth2-oidc';
import * as rs from 'jsrsasign';

export class IdpAxwayValidationHandler implements ValidationHandler {
  static calcHash(valueToHash: string, algorithm: string): string {
    const hashAlg = new rs.KJUR.crypto.MessageDigest({alg: algorithm});
    const result = hashAlg.digestString(valueToHash);
    return IdpAxwayValidationHandler.toByteArrayAsString(result);
  }

  static toByteArrayAsString(hexString: string) {
    let result = '';
    for (let i = 0; i < hexString.length; i += 2) {
      const hexDigit = hexString.charAt(i) + hexString.charAt(i + 1);
      const num = parseInt(hexDigit, 16);
      result += String.fromCharCode(num);
    }
    return result;
  }

  /**
   * Infers the name of the hash algorithm to use
   * from the alg field of an id_token.
   *
   * @param jwtHeader the id_token's parsed header
   */
  protected static inferHashAlgorithm(jwtHeader: object): string {
    const alg: string = jwtHeader['alg'];

    if (!alg.match(/^.S[0-9]{3}$/)) {
      throw new Error('Algorithm not supported: ' + alg);
    }

    return 'sha' + alg.substring(2);
  }

  validateSignature(validationParams: ValidationParams): Promise<any> {
    return Promise.resolve(null);
  }

  validateAtHash(params: ValidationParams): Promise<boolean> {
    if (!params.accessToken || !params.idTokenClaims || !params.idTokenClaims['at_hash_icdc']) {
      return Promise.resolve(false);
    }

    const hashAlg = IdpAxwayValidationHandler.inferHashAlgorithm(params.idTokenHeader);

    const tokenHash = IdpAxwayValidationHandler.calcHash(params.accessToken, hashAlg);
    const leftMostHalf = tokenHash.substring(0, tokenHash.length / 2);
    const tokenHashBase64 = btoa(leftMostHalf);
    const claimsAtHash = params.idTokenClaims['at_hash_icdc'].replace(/=/g, '');
    const atHash = tokenHashBase64
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');

    if (atHash !== claimsAtHash) {
      console.error('>>> exptected at_hash: ' + atHash);
      console.error('>>> actual at_hash: ' + claimsAtHash);
    }

    return Promise.resolve(atHash === claimsAtHash);
  }
}
