/** @author Maelig GOHIN*/
// @dynamic
export class ObjectUtils {

  /**
   * Check whether the {@code obj} is null or undefined
   * @param obj obj to test
   */
  public static isNullOrUndefined(obj: any): boolean {
    return typeof obj === 'undefined' || obj === null;
  }

  /**
   * Check that {@code obj} is not null and not undefined.
   * If {@code property} is set, it'll check that the {@code} property is not null or undefined
   *
   * More info about property extraction in doc of method {@link this.extractPropertyValue}
   *
   * @param obj object to test
   * @param property property to get
   */
  public static isNotNull(obj: any, property?: string): boolean {
    if (this.isNullOrUndefined(property)) {
      return !this.isNullOrUndefined(obj);
    }

    return !this.isNullOrUndefined(this.extractPropertyValue(obj, property));
  }

  /**
   * Reverse of {@link ObjectUtils#isNotNull}
   */
  public static isNull(obj: any, property?: string): boolean {
    return !this.isNotNull(obj, property);
  }

  /**
   * Extract a property of object {@code baseObj}.
   *
   * Property can be a simple one like 'color' or a dot annotation 'engine.battery.model'
   *
   * It'll only go through Objects using brackets notation obj[propertySplitted]
   *
   * @param baseObj base object
   * @param property property to get
   */
  public static extractPropertyValue(baseObj: any, property: string): any {
    if (this.isNullOrUndefined(baseObj) || this.isNullOrUndefined(property) || !property.trim().length) {
      return null;
    }

    const propertySplit = property.split('.');

    if (!propertySplit.length) {
      return null;
    }

    // go through item with dot annotation properties
    let obj = baseObj;

    for (const prop of propertySplit) {
      obj = obj[prop];
      if (this.isNullOrUndefined(obj)) {
        return null;
      }
    }

    return obj;
  }

  /**
   * Check if {@code str} is a not empty string (trimmed)
   * @param str string to test
   * @param property property to get
   */
  public static stringIsNullOrEmpty(str?: any, property?: string): boolean {
    if (ObjectUtils.isNotNull(str)) {
      if (typeof str === 'string') {
        return str.trim().length === 0;
      }
      return ObjectUtils.stringIsNullOrEmpty(ObjectUtils.extractPropertyValue(str, property));
    }
    return true;
  }

  public static stringEquals(s1: string, s2: string): boolean {
    if (this.isNullOrUndefined(s1) && this.isNullOrUndefined(s2)) {
      return true;
    }

    if (this.isNullOrUndefined(s1) || this.isNullOrUndefined(s2)) {
      return false;
    }

    return s1 === s2;
  }

  /**
   * Create a new object merging both {@code target} and {@code source}
   *
   * @param target {object} target object
   * @param source {object} source object
   *
   * @returns {object} merged object
   */
  public static mergeDeep<T extends Object>(target: T, source: T): T {
    const output = Object.assign({}, target);
    if (ObjectUtils._isObject(target) && ObjectUtils._isObject(source)) {
      Object.keys(source).forEach(key => {
        if (ObjectUtils._isObject(source[key])) {
          if (!(key in target)) {
            Object.assign(output, {[key]: source[key]});
          } else {
            output[key] = ObjectUtils.mergeDeep(target[key], source[key]);
          }
        } else {
          Object.assign(output, {[key]: source[key]});
        }
      });
    }
    return output;
  }

  /**
   * Check if {@code item} is an object
   *
   * @param item {any} item to check
   *
   * @returns true if {@code item} is an object, else false
   */
  public static _isObject(item): boolean {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }
}
