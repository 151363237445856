import {Injectable} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LocalStoreManager} from 'boot/service/local-store-manager.service';
import {SecurityService} from 'security/service/oauth-security.service';

declare var Ie3IhmCommun;

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {

  private _lastUrl = '/';

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _localStoreManager: LocalStoreManager,
    private readonly _securityService: SecurityService
  ) {

  }

  public startRecording(): void {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary')
      )
      .subscribe(event => {
        this._lastUrl = '/' + event.pathFromRoot
          .map(r => r.snapshot.url)
          .filter(f => !!f[0])
          .map(([f]) => f.path)
          .join('/');
      });
  }

  public saveCurrentUrl(): void {
    this._localStoreManager.setItem('last-url', this._lastUrl);
  }

  public redirect(): void {
    const lastUrl = this._localStoreManager.getItem('last-url');
    this._localStoreManager.removeItem('last-url');
    this._router.navigateByUrl(lastUrl || '/');
  }

  public redirectionAvecDroitsEmployeurTitulaireVers(path: string): void {
    this._securityService.refreshUtilisateurConnectePourEmployeurTitulaire()
      .then(connectedUser => {
        Ie3IhmCommun.setDelegationInformations(connectedUser.getDelegationInformations());
        this._router.navigateByUrl(path);
      }, () => {
        this._router.navigateByUrl('/erreurs/crash');
      });
  }

  public redirectionAvecDroitsEmployeurDelegationVers(path: string, bcrEmployeurCible: string, successCallback?: Function, errorCallback?: Function): void {
    this._securityService.refreshUtilisateurConnectePourEmployeurEnDelegation(bcrEmployeurCible)
      .then(connectedUser => {
        Ie3IhmCommun.setDelegationInformations(connectedUser.getDelegationInformations());
        this._router.navigateByUrl(path);
        if (successCallback) {
          successCallback();
        }
      }, () => {
        this._router.navigateByUrl('/erreurs/crash');
        if (errorCallback) {
          errorCallback();
        }
      });
  }

  public redirectionEmployeurDelegationVers(path: string) {
    Ie3IhmCommun.setDelegationInformations(this._securityService.getConnectedUser().getDelegationInformations());
    this._router.navigateByUrl(path);
  }
}
