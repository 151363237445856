import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { RedirectionService } from '@shared/services/redirection.service';
import { MatomoService } from 'boot/analytics/matomo.service';
import { Constantes } from 'boot/constantes';
import { IhmCommunService } from 'boot/service/ihm-commun.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SecurityUser } from 'security/entity/security-user.model';
import { SecurityService } from 'security/service/oauth-security.service';

import { EnvironmentService } from './context/environment.service';
import { LocalStoreManager } from './service/local-store-manager.service';

declare var Ie3IhmCommun;
declare var Ie3IhmCommunGestionCookiesService;
declare var Ie3IhmCommunEventsHandler;
declare var Ie3IhmCommunLiensEvitement;
declare var Ie3IhmCommunStore;

/* tslint:disable */
@Component({
  selector: 'body',
  template: `
    <div id="ie3IhmCommunLiensEvitement"></div>
    <ie3-route-loading [visible]="loading"></ie3-route-loading>
    <div id="ie3IhmCommunHeader"></div>
    <div id="ie3IhmCommunEnvironnement"></div>
    <main id="main" role="main" tabindex="-1">
      <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
      <div [class.d-none]="loading" id="ie3IhmCommunFooter"></div>
    </main>
    <!-- <div id="ie3IhmCommunNotifications"></div>
     <ie3-matomo></ie3-matomo>-->
  `

})
/* tslint:enable */
export class BootComponent implements OnInit {
  loading = true;

  constructor(
    private readonly _securityService: SecurityService,
    private readonly _environmentService: EnvironmentService,
    private readonly _translateService: TranslateService,
    private readonly _localStoreManager: LocalStoreManager,
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _matomoService: MatomoService,
    private readonly _ihmCommunService: IhmCommunService,
    private readonly _redirectionService: RedirectionService,
    private readonly _ngbTooltipConfig: NgbTooltipConfig
  ) {
    // évite plein de problèmes, surtout pour IE
    _ngbTooltipConfig.container = 'body';
  }

  ngOnInit() {
    this._translateService.setDefaultLang('fr');
    this._translateService.use('fr');

    this._securityService.initializeOauthConfiguration(this._environmentService.config.oauth, this._environmentService.config.userProfileKeys);

    this._securityService.onErrorEvents().subscribe(e => {
      this._securityService.initLoginFlow();
    });

    this._securityService.onAccessTokenExpireEvent()
      .subscribe(e => {
        this._securityService.refreshToken();
      });

    this._redirectionService.startRecording();

    this._handleRoutingLoader();

    // action préventive si l'utilisateur a bloqué son compte dans 'mon compte'
    // et qu'il a rafraichi la page pour ne pas être déconnecté
    const compteBloque = this._localStoreManager.getItem(Constantes.STORAGE_KEY_COMPTE_BLOQUE);
    if (compteBloque) {
      this._localStoreManager.removeItem(Constantes.STORAGE_KEY_COMPTE_BLOQUE);
      this._router.navigateByUrl('/logout');
      return;
    }

    const provientDuMailDeDemandeDeDelegation = this._getQueryParamByName(Constantes.URL_PARAM_PROVENANCE_MAIL_DELEGATION);
    if (provientDuMailDeDemandeDeDelegation) {
      this._localStoreManager.setItem(Constantes.STORAGE_KEY_PROVENANCE_MAIL_DELEGATION, 'true');
    }

    const path = this._location.path(true);

    this._securityService.tryOauth()
      .then(success => {
        if (!success || this.checkIsDisconnected(path) ) {
          this._securityService.logout();
          return;
        }
        this._loadUserInfo()
          .pipe(
            mergeMap(() => this._initHeader())
          )
          .subscribe(
            () => {
              this._router.navigateByUrl(path);
            },
            e => {
              this._logErrorAndRedirectToCrash(e);
            });
      });
  }

  onRouterOutletActivate(event: any): void {
    this.loading = false;
  }

  private _handleRoutingLoader(): void {
    this._router.events.subscribe((routerEvent: RouterEvent) => {

      if (routerEvent instanceof NavigationStart) {
        this.loading = true;
      }

      if (routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError) {
        this.loading = false;
      }
    });
  }

  /**
   * Charge le userInfo
   * Même en cas d'erreur on fait un next car l'utilisateur n'est
   * peut être pas connecté
   */
  private _loadUserInfo(): Observable<void> {
    return new Observable<void>(subscriber => {
      try {
        this._securityService.loadConnectedUser()
          .then(() => {
            subscriber.next();
            subscriber.complete();
          })
          .catch(() => {
            subscriber.next();
            subscriber.complete();
          });
      } catch (e) {
        subscriber.next();
        subscriber.complete();
      }

    });
  }

  /**
   * Initialise le header en fonction de l'état de connexion de l'utilisateur
   */
  private _initHeader(): Observable<void> {
    return new Observable<void>(subscriber => {
      const connectedUser = this._securityService.getConnectedUser();
      if (connectedUser) {
        const user: SecurityUser = connectedUser;
        this._ihmCommunService.get()
          .subscribe(lib => {
              if (lib) {
                Ie3IhmCommun.setUser({
                  profil: user.profil,
                  nom: user.nom,
                  prenom: user.prenom,
                  designation: user.designationEmployeurTitulaire,
                  habilitations: user.habilitations
                });
                Ie3IhmCommun.setAccessToken(this._securityService.getAccessToken());
                Ie3IhmCommun.setDelegationInformations(user.getDelegationInformations());
                this._activerMatomoSiValidationConsentement();
                this._ajouterLesLiensEvitement();
                subscriber.next();
                subscriber.complete();
              }
            },
            e => {
              subscriber.error(e);
              subscriber.complete();
            });
      } else {
        this._ihmCommunService.get()
          .subscribe(lib => {
            if (lib) {
              lib.header.setRegistrationLinkVisibility(false);
              lib.header.setConnectionLinkVisibility(true);
              this._activerMatomoSiValidationConsentement();
              this._ajouterLesLiensEvitement();
              subscriber.next();
              subscriber.complete();
            }
          });
      }
    });
  }

  /**
   * Active Matomo si l'utilisateur valide ou a déjà validé l'utilisation des cookies
   */
  private _activerMatomoSiValidationConsentement(): void {
    Ie3IhmCommunGestionCookiesService.addListener((specificConsents, consentEnabled) => {
      if (consentEnabled) {
        if (specificConsents && specificConsents.matomo === true) {
          this._matomoService.init();
          this._matomoService.giveConsent();
        } else {
          this._matomoService.removeConsent();
        }
      }
    });
  }

  private _ajouterLesLiensEvitement(): void {
    const libelleContenu = this._translateService.instant('accessibilite.liensEvitement.contenu');
    const libelleMenu = this._translateService.instant('accessibilite.liensEvitement.menu');

    Ie3IhmCommunEventsHandler.addListener('LIENS_EVITEMENT_CLICK', (eventName, link, event) => {
      if (link.id === 'contenu') {
        event.preventDefault();
        const filAriane = document.getElementsByTagName('ie3-fil-ariane')[0] as HTMLElement;
        if (filAriane) {
          const firstChildFilArianeFocusable = filAriane.firstChild as HTMLElement;
          window.scroll(0, 0);
          if (typeof firstChildFilArianeFocusable.focus === 'function') {
            firstChildFilArianeFocusable.focus();
          }
        }
      } else if (link.id === 'menu') {
        event.preventDefault();
        const menuLinks = document.getElementsByClassName('ie3-ihm-commun-menu-link');
        if (menuLinks.length) {
          (<HTMLAnchorElement>menuLinks[0]).focus();
        } else {
          const headerLinks = document.getElementsByClassName('ie3-ihm-commun-header-navbar-right-panel-item');
          if (headerLinks.length) {
            (<HTMLAnchorElement>headerLinks[0]).focus();
          }
        }
      }
    });
    Ie3IhmCommunLiensEvitement.addLinks([
      new Ie3IhmCommunLiensEvitement
        .LienEvitementBuilder()
        .withId('contenu')
        .withLabel(libelleContenu)
        .withClickEvent()
        .build(),
      new Ie3IhmCommunLiensEvitement
        .LienEvitementBuilder()
        .withId('menu')
        .withLabel(libelleMenu)
        .withClickEvent()
        .build()
    ]);
  }

  private _logErrorAndRedirectToCrash(e) {
    if (e) {
      console.error(e);
    }
    this._router.navigateByUrl('/erreurs/crash');
  }

  private _getQueryParamByName(name): string {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[#?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return null;
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  private checkIsDisconnected(path: string) {
    const lastIntercationTime = Number(this._localStoreManager.getItem(Constantes.LAST_INTERACTION));
    const timedisconnect = Number(this._localStoreManager.getItem(Constantes.TIME_DECONNEXION));
    const verifyTime = new Date().getTime() - ( lastIntercationTime + timedisconnect) ;
    return (lastIntercationTime && lastIntercationTime > 0 && verifyTime > 0 && path.includes('#')) ;
  }
}
