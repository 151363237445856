import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './service/auth-guard.service';
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {IcdcOauthSecurityService} from './service/icdc-oauth-security.service';
import {OauthSecurityServiceMock} from './service/oauth-security-mock.service';
import {SecurityService} from './service/oauth-security.service';
import {LocalStoreManager} from 'boot/service/local-store-manager.service';
import {EnvironmentService} from 'boot/context/environment.service';
import {PublicAuthenticatedGuard} from 'security/service/public-auth-guard.service';
import {NoAuthGuard} from 'security/service/no-auth-guard.service';
import {HabilitationsGuard} from 'security/service/habilitations-guard.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TitulaireAuthGuard} from 'security/service/titulaire-auth-guard.service';
import {DelegationAuthGuard} from 'security/service/delegation-auth-guard.service';
import { environment } from '../environments/environment';

export function securityServiceFactory(
  oauthService: OAuthService,
  localStoreManager: LocalStoreManager,
  environmentService: EnvironmentService,
  httpClient: HttpClient) {
  console.log('securityServiceFactory - ENV : ');
  console.log(environment.ENV);

  if (environment.E2E === true || !environmentService.config.authEnabled) {
    return new OauthSecurityServiceMock(localStoreManager);
  }

  return new IcdcOauthSecurityService(oauthService, localStoreManager, httpClient);
}

@NgModule({
  imports: [CommonModule, OAuthModule.forRoot(), HttpClientModule],
  providers: [
    AuthGuard,
    HabilitationsGuard,
    PublicAuthenticatedGuard,
    NoAuthGuard,
    TitulaireAuthGuard,
    DelegationAuthGuard,
    {
      provide: SecurityService,
      useFactory: securityServiceFactory,
      deps: [OAuthService, LocalStoreManager, EnvironmentService, HttpClient]
    }
  ]
})
export class SecurityModule {
}
