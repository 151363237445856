import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParametrageModel } from '@shared/models/parametrage/parametrage.model';
import { EnvironmentService } from 'boot/context/environment.service';
import { HttpClient } from '@angular/common/http';
import { Endpoint } from '@shared/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ParametrageService {
  protected _parametrage: ParametrageModel;

  constructor(private readonly _httpClient: HttpClient, private readonly _environmentService: EnvironmentService) {}

  public get(): ParametrageModel {
    return this._parametrage;
  }

  public load(): Observable<ParametrageModel> {
    if (this._parametrage) {
      return of(this._parametrage);
    }

    return new Observable<ParametrageModel>(subscriber =>
      this._httpClient
        .get(Endpoint.PARAMETRES)
        .pipe(map(response => ParametrageModel.from(response)))
        .subscribe(
          parametrage => {
            this._parametrage = parametrage;
            subscriber.next(this._parametrage);
            subscriber.complete();
          },
          (...args) => {
            subscriber.error(args);
            subscriber.complete();
          }
        )
    );
  }
}
