<div style="background-color: #F3F3F3;" class="ps-3 pb-3 pr-3 pt-1" tabindex="0">
  <div class="col-12 mt-4">
    <h1 class="breadcrumb justify-content-center">Plan du site</h1>
  </div>
</div>

<div class="sitemapContainer">
  <h3 class="titre-thematique-css">Extranet Gestionnaires IER</h3>

  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/">Se connecter</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/public/inscription">Créer un compte</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/public/identifiant/recuperation">Récupérer votre identifiant</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/public/mot-de-passe/regeneration">Réinitialiser votre mot de passe</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a target="_blank" href="{{urlDocumentML}}">Mentions légales</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a target="_blank" href="{{urlDocumentCgus}}">Conditions générales d'utilisation</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a target="_blank" href="{{urlDocumentRgpd}}">Données personnelles</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a class="lien" (click)="openModalCookies()" style="cursor: pointer;">Gérer les cookies</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="">Accessibilité </a></div>

  <h4 class="titre-thematique-css">Espace connecté</h4>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/">Tableau de bord</a></div>

  <h4 class="titre-thematique-css">Thématiques</h4>
  <div *ngFor="let thematique of thematiques" class="ms-4">
    <em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em>
    <a routerLink="/thematiques/{{ thematique.id }}">{{ thematique.titre }}</a>
  </div>

  <h4 class="titre-thematique-css">Mon compte</h4>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/compte/profil/">Mon profil</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/compte/mot-de-passe/">Mon mot de passe</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/compte/droits/">Mes droits</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/compte/intervenants/">Mes administrateurs</a></div>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a href="{{urlEtablissement}}">Mon établissement</a></div>

  <h4 class="titre-thematique-css">Gestion des comptes</h4>
  <div class="ms-4"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/utilisateurs/">Gestion des comptes</a></div>
  <div class="ms-5"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/utilisateurs/creation/">Créer un compte</a></div>
  <div class="ms-5"><em class="md-icon md-16 me-2" aria-hidden="true">stop_circle</em><a routerLink="/utilisateurs/liste/historique/">Historique</a></div>
</div>
