export enum Action {
  ACTIVATION_COMPTE_UTILISATEUR = 'ACTIVATION_COMPTE_UTILISATEUR',
  REGENERATION_MDP = 'REGENERATION_MDP'
}

export class JetonLienActionModel {
  readonly jeton: string;
  readonly action: Action;

  constructor(jeton: string, action: Action) {
    this.jeton = jeton;
    this.action = action;
  }
}
