import {TranslateService} from '@ngx-translate/core';
import {AbstractIe3FilArianeService, ObjectUtils} from 'ie3-ihm-lib-ngx';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Ie3FilArianeService extends AbstractIe3FilArianeService {
  constructor(
    private readonly _translateService: TranslateService
  ) {
    super();
  }

  getLibelle(cleTraduction: string): Observable<string> {
    if (ObjectUtils.stringIsNullOrEmpty(cleTraduction)) {
      return of('');
    }

    return this._translateService.get(cleTraduction);
  }

  getUrl(url: string): string {
    return url;
  }
}
