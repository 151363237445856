import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ie3-page-error-generic',
  templateUrl: './error.generic.component.html',
  styleUrls: ['./error.generic.component.scss']
})
export class ErrorGenericComponent implements OnInit {
  @Input()
  codeErreur = 500;

  @Input()
  afficherHeaderPage = true;

  @Input()
  afficherConsigne = true;

  @Input()
  afficherConsigneComplements = true;

  @Input()
  afficherRetourTdb = false;

  @Input()
  afficherRetourLogin = false;

  srcImagePage: string;
  commentaireI18nKey: string;
  consigneI18nKey: string;
  retourTdBI18nKey: string;
  retourLoginI18nKey: string;
  consigneComplementsI18nKey: string;

  constructor(
    private readonly _activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.srcImagePage = `assets/img/errors/err_${this.codeErreur}.svg`;

    const baseIe18n = 'pageErreur.' + this.codeErreur;
    this.commentaireI18nKey = baseIe18n + '.commentaire';

    if (this.afficherConsigne) {
      this.consigneI18nKey = baseIe18n + '.consigne';
    }
    if (this.afficherConsigneComplements) {
      this.consigneComplementsI18nKey = baseIe18n + '.consigne-suite';
    }

    if (this.afficherRetourLogin) {
      this.retourLoginI18nKey = baseIe18n + '.login';
    }

    if (this._activatedRoute.snapshot.data.afficherRetourTdb === true) {
      this.afficherRetourTdb = true;
    }

    if (this._activatedRoute.snapshot.data.afficherHeaderPage === false) {
      this.afficherHeaderPage = false;
    }

    if (this.afficherRetourTdb) {
      this.retourTdBI18nKey = baseIe18n + '.tdb';
    }
  }
}
