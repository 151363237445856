import {Component} from '@angular/core';

@Component({
  selector: 'ie3-crash',
  templateUrl: './crash.component.html',
  styleUrls: ['./crash.component.scss']
})
export class CrashComponent {

}
